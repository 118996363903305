import React, { useContext } from 'react';
import { PhotoIcon } from "@heroicons/react/24/solid";
import { AppContext } from '../context/AppContext';


type Props = {
    abosolute?: boolean
}
const PostPlaceholder: React.FC<Props> = ({ abosolute = true }) => {
    const appContext = useContext(AppContext)
    return (

        <div className={`opacity-90  ${abosolute ? 'absolute top-0 left-0' : ''} overflow-hidden`}>
            <div className="flex bg-gray-300 rounded-lg my-5 flex-col" style={{ width: appContext.postSize.width }}>
                <div className="rounded-lg animate-pulse relative" style={{ width: appContext.postSize.width, height: appContext.postSize.height }}>
                    <span className="flex bg-slate-100 rounded-lg items-center justify-center" style={{ width: appContext.postSize.width, height: appContext.postSize.height }} >
                        <PhotoIcon className='w-20 h-20 bg-slate-400 opacity-30' />
                    </span>
                </div>

                <div className="p-2">

                    <div className="flex animate-pulse flex-row justify-between items-center">
                        <div className="flex mr-5">
                            <div className="pt-2" >
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                                <span className="inline-block animate-pulse bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2 w-20 h-10" />
                            </div>

                        </div>

                        <div className="flex flex-row justify-end py-2 whitespace-nowrap " >

                            <span className='bg-gray-100' style={{ width: 25, height: 40 }} />
                            <span className='bg-gray-50' style={{ width: 10, height: 40 }} />
                            <span className='bg-gray-200' style={{ width: 10, height: 40 }} />
                            <span className='bg-gray-400' style={{ width: 30, height: 40 }} />
                            <span className='bg-gray-100' style={{ width: 8, height: 40 }} />
                            <span className='bg-gray-500' style={{ width: 5, height: 40 }} />

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PostPlaceholder