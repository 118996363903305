import { Post } from '../types';
import { getAppCheckToken } from './appCheck';
import { getLikes } from './likes';

export const getPosts = async (): Promise<Post[]> => {
    // Include the App Check token with requests to your server.
    const token = await getAppCheckToken();

    const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_GET_POSTS_URL : process.env.REACT_APP_PROD_GET_POSTS_URL

    if (!token || !url) throw new Error('Somethnig went wrong');

    const apiResponse = await fetch(url, {
        headers: {
            'X-Firebase-AppCheck': token,
        }
    });

    const data = await apiResponse.json() as unknown as Post[]

    return enrichPostsWithLikes(data)
}

export const enrichPostsWithLikes = async (posts: Post[]): Promise<Post[]> => {
    const resolvedPosts = await Promise.all(posts.map(async (post) => {
        return enrichPostWithLikes(post)
    }))

    return resolvedPosts
}

export const enrichPostWithLikes = async (post: Post): Promise<Post> => {
    const likes = await getLikes(post.id)
    const enrichedPost = { ...post, likes }
    return enrichedPost
}

export const getPaginatePosts = async (postId: string): Promise<Post[]> => {
    // Include the App Check token with requests to your server.
    const token = await getAppCheckToken();
    const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_GET_POSTS_PAGINATE_URL : process.env.REACT_APP_PROD_GET_POSTS_PAGINATE_URL
    if (!token || !url) throw new Error('Somethnig went wrong');

    const apiResponse = await fetch(`${url}${postId}`, {
        headers: {
            'X-Firebase-AppCheck': token,
        }
    });

    const data = await apiResponse.json() as unknown as Post[]

    return enrichPostsWithLikes(data)
}

export const getPost = async (id: string): Promise<Post | undefined> => {
    const token = await getAppCheckToken();
    const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_GET_POST_URL : process.env.REACT_APP_PROD_GET_POST_URL
    if (!token || !url) throw new Error('Somethnig went wrong');

    const apiResponse = await fetch(`${url}${id}`, {
        headers: {
            'X-Firebase-AppCheck': token,
        }
    });

    const data = await apiResponse.json() as unknown as Post

    return enrichPostWithLikes(data)
}