import { useCallback, useEffect, useState } from "react";

export const useToast = () => {
    const [showToast, setToast] = useState(false)
    const [timeoutId, setTimeOutId] = useState<NodeJS.Timer | null>(null)
    const [toastMessage, setToastMessage] = useState('')

    const toggleToast = useCallback((meesage: string) => {
        setToast(true);
        const myTimeout = setTimeout(() => setToast(false), 2500);
        setTimeOutId(myTimeout)
        setToastMessage(meesage)

    }, [])

    useEffect(() => {

        return () => {
            if (timeoutId) clearInterval(timeoutId)
        }

    }, [timeoutId])


    return {
        toggleToast,
        showToast,
        toastMessage
    }
}
