import React, { useState } from "react";
import { HeartIcon, StopCircleIcon, } from '@heroicons/react/24/solid'
import LoadingButton from "./LoadingButton";
import { consentForEmailUpdate } from "../services/email";

type Props = {
    showModal: boolean
    onClose: () => void
}


const AuthModal: React.FC<Props> = ({ showModal, onClose }) => {
    const [informMeCheckbox, setInformMeCheckbox] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false);

    const onModalClose = () => {
        setEmailAddress("")
        setError(false)
        setInformMeCheckbox(false)
        onClose()
    }
    const onSubmit = async () => {
        if (!informMeCheckbox) {
            setError(true)
        } else {
            setLoading(true)
            await consentForEmailUpdate(emailAddress)
            setLoading(false)
            onModalClose()
        }
    }

    const onCheck = () => {
        setInformMeCheckbox(!informMeCheckbox)
        if (error) {
            setError(false)
        }
    }

    return (
        <>
            {showModal ? (
                <>
                    <div tabIndex={-1} aria-hidden="true" className="flex visible overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full md:inset-0 h-modal backdrop-blur-lg items-center justify-center">
                        <div className="flex relative pixel p-4 max-w-xl h-full md:h-auto items-center justify-center">
                            {/*content*/}
                            <div className="border-0 bg-regal-blue rounded-3xl shadow-lg relative flex flex-col w-full h-fit outline-none focus:outline-none justify-center lg:mt-0 md:mt-0 mt-48" >
                                {/*header*/}
                                <div className="flex justify-between items-start p-4 rounded-t">
                                    <button onClick={onModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 ease-out duration-300 hover:scale-110 rounded-full text-sm p-1.5 ml-auto inline-flex items-center">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="px-6 lg:px-8 overflow-y-scroll">
                                    <>
                                        <div className="space-y-2">
                                            <div className="flex flex-col md:flex-row lg:flex-row items-start md:items-center lg:items-center space-x-0 md:space-x-2 lg:space-x-2 space-y-2 md:space-y-0 lg:space-y-0">
                                                <h3 className="bg-gradient-to-r from-green-400 to-blue-500 text-base font-medium p-2 rounded-lg text-white justify-center items-center flex flex-row">
                                                    Shospot
                                                </h3>
                                                <p className="self-start md:self-center lg:self-center text-sm">Bringing inspiration and organization to your design projects</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-auto h-auto mt-2">
                                            <div className="pt-2 pb-4">
                                                <p className="text-slate-300 opacity-70 py-2 "> {"Hold your horses, interior design lovers! Our mood-board creating tool is almost ready to gallop into your life. Be the first to saddle up by joining our waiting list. You'll get to be the first to know when we launch!"}</p>

                                                <p className="text-slate-300 opacity-70 py-2 "> {"What to expect: "}</p>

                                                <div className="flex flex-row p-2 space-x-2 items-center">
                                                    <StopCircleIcon className="text-white w-5 h-5 animate-pulse" />  <p> Generate interior design images with AI</p>
                                                </div>

                                                <div className="flex flex-row p-2 space-x-2 items-center">
                                                    <StopCircleIcon className="text-white w-5 h-5 animate-pulse" />  <p>Create design mood boards</p>
                                                </div>

                                                <div className="flex flex-row p-2 space-x-2 items-center">
                                                    <StopCircleIcon className="text-white w-5 h-5 animate-pulse" />  <p>Curate product lists</p>
                                                </div>

                                                <div className="flex flex-row p-2 space-x-2 items-center">
                                                    <StopCircleIcon className="text-white w-5 h-5 animate-pulse" />  <p>Reimagine your space with AI </p>
                                                </div>

                                                <div className="flex flex-row p-2 space-x-2 items-center">
                                                    <StopCircleIcon className="text-white w-5 h-5 animate-pulse" />  <p>Create custom collections</p>
                                                </div>

                                                <div className="flex flex-row p-2 space-x-2 items-center">
                                                    <StopCircleIcon className="text-white w-5 h-5 animate-pulse" />  <p>And more</p>
                                                </div>
                                            </div>

                                            <p className="text-slate-300 opacity-70 pb-4">{"Don't be the last one to the rodeo, Join the waiting list now!"}</p>

                                            {error && <p className="text-red-300 text-sm opacity-70 py-2 animate-pulse">Please confirm your consent by selecting the checkbox below</p>}
                                            <div className="flex flex-row items-center justify-evenly w-full space-x-1">
                                                <input value={emailAddress} onChange={(event) => setEmailAddress(event.target.value)} type="email" name="email" id="email" className="bg-white border border-gray-300 text-gray-900 text-sm rounded-2xl focus:ring-regal-blue block w-full p-2.5 focus:border-regal-blue" placeholder="Email address" required />
                                                <LoadingButton loading={loading} title={"Submit"} onClick={() => onSubmit()} />
                                            </div>
                                            <div className="flex flex-row items-center pt-3 w-full space-x-1">
                                                <input id="default-checkbox" type="checkbox" onChange={onCheck} checked={informMeCheckbox} className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label className="ml-2 text-xs font-medium text-slate-300 dark:text-gray-300">Yes, inform me of the progress</label>
                                            </div>

                                        </div>
                                    </>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end pr-4 pb-3 rounded-b">
                                    <p className="text-slate-500 text-sm leading-relaxed"> Built with</p>
                                    <HeartIcon className="h-4 w-4 text-blue-400 animate-bounce hover:bg-blue-100 hover:cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}


export default AuthModal