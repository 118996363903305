import { HeartIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import { updatePostLikes } from '../services/likes'
import { LikeAction } from '../types'
import { getStorageValue, setStorageValue } from '../utils/localStorage'
import LikeAnimation from './LikeAnimation'
import { LIKES } from '../constants/localStorage'


type Props = {
    postId: string,
    likesCount: number
}

const likedStyle = 'bg-regal-blue p-2 lg:hover:bg-gray-200 md:hover:bg-gray-200 text-white lg:hover:text-gray-600 md:hover:text-gray-600'
const notLikedStyle = 'bg-gray-200 p-2 lg:hover:bg-regal-blue md:hover:bg-regal-blue text-gray-600 lg:hover:text-white md:hover:text-white'

const Like: React.FC<Props> = ({ postId, likesCount }) => {

    const likes = getStorageValue(LIKES, {})
    const [liked, setLiked] = useState(!!likes[postId]);
    const [showAnimation, setShowAnimation] = useState(false)
    const [internalLikesCount, setInternalLikesCount] = useState<number>(() => likesCount ? likesCount : 0)


    const handleLikeClick = () => {
        const listOfLikes = getStorageValue(LIKES, {})
        if (listOfLikes[postId]) {
            delete listOfLikes[postId]
            setStorageValue(LIKES, listOfLikes)
            setLiked(false)
            if (internalLikesCount > 0) {
                setInternalLikesCount(internalLikesCount - 1)
                updatePostLikes(postId, LikeAction.UNLIKE)
            }
        }
        else {
            const newValue = { ...listOfLikes, [postId]: true }
            setStorageValue(LIKES, newValue)
            setShowAnimation(true)
            setLiked(true)
            setInternalLikesCount(internalLikesCount + 1)
            updatePostLikes(postId, LikeAction.LIKE)
        }
    }

    return (
        <div className="flex flex-row items-center space-x-2">
            <button onClick={() => handleLikeClick()} className={`ease-out duration-100 hover:scale-110 rounded-full animate ${liked ? likedStyle : notLikedStyle} opacity-80`}>
                <>
                    <LikeAnimation show={showAnimation} onComplete={() => setShowAnimation(false)} />
                    {!showAnimation && <HeartIcon className={`h-4 w-4 rounded-full text-inherit`} />}
                </>
            </button>

            <p className="text-gray-500 text-sm">{internalLikesCount}</p>
        </div>
    )
}

export default Like