import { useState } from "react";
import { updatePostLikes } from '../services/likes'
import { LikeAction } from '../types'
import { getStorageValue, setStorageValue } from '../utils/localStorage'


const useLikes = (postId: string, likesCount: number) => {
    const likes = getStorageValue('likes', {})
    const [liked, setLiked] = useState(!!likes[postId]);
    const [showAnimation, setShowAnimation] = useState(false)
    const [internalLikesCount, setInternalLikesCount] = useState<number>(() => likesCount ? likesCount : 0)


    const handleLikeClick = () => {
        setShowAnimation(true)
        const listOfLikes = getStorageValue('likes', {})
        if (listOfLikes[postId]) {
            delete listOfLikes[postId]
            setStorageValue('likes', listOfLikes)
            setLiked(false)
            if (internalLikesCount > 0) {
                setInternalLikesCount(internalLikesCount - 1)
                updatePostLikes(postId, LikeAction.UNLIKE)
            }
        }
        else {
            const newValue = { ...listOfLikes, [postId]: true }
            setStorageValue('likes', newValue)
            setShowAnimation(true)
            setLiked(true)
            setInternalLikesCount(internalLikesCount + 1)
            updatePostLikes(postId, LikeAction.LIKE)
        }
    }

    return {
        liked,
        showAnimation,
        handleLikeClick,
        setShowAnimation,
        setLiked
    }
}

export default useLikes