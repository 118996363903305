import React from 'react'
import { Product } from '../types'

type Props = {
    product: Product
}

const ProductCard: React.FC<Props> = ({ product }) => {

    if (!product.link) return null;

    return (
        <a className="bg-regal-blue2 ease-out duration-300 hover:scale-105 shadow rounded-xl px-4 pt-2 pb-4 max-w-sm h-fit w-full mx-1" href={product.link} target="_blank" style={{ width: 200, minWidth: 200, maxWidth: 200 }} rel="noreferrer">
            <div className="flex flex-col">
                <div className="flex self-center items-center justify-center rounded-md h-32 w-32 my-2 ">
                    {product.image && <img className="rounded-lg" style={{ height: 'inherit', width: 'inherit' }} src={product.image} alt="description" />}
                </div>

                <div className="flex flex-col space-y-3 mt-2">
                    <div className="h-auto text-xs whitespace-nowrap overflow-hidden overflow-ellipsis text-slate-300 rounded">
                        {product.title}
                    </div>

                    <div className="flex flex-row justify-between">
                        <div className="h-auto text-xs text-slate-400 rounded col-span-2">
                            {product.price}
                        </div>

                        <div className="h-auto text-xs text-slate-400  rounded col-span-1">
                            {product.brand}
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default ProductCard