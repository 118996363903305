import React from 'react'
import Lottie from "lottie-react";
import emptyLikes from "../lottie/like.json";

const EmptyLikes = () => {
    return <div className='flex flex-col space-y-2 items-center'>
        <div className='bg-slate-200 opacity-60 rounded-full'>
            <Lottie className='w-40 h-40 lg:w-60 lg:h-60 md:w-60 md:h-60 opacity-90' animationData={emptyLikes} />
        </div>
        <h2 className='font-medium text-center text-slate-400'>{"You currently don't have any likes"}</h2>
    </div>
};

export default EmptyLikes;