import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import MetaTags from './components/MetaTags';
import Post from './components/Post';
import UIIndicator, { ContentType } from './components/UIIndicator';
import * as searchService from './services/search'
import { Post as PostType } from './types';

const PostScreen: React.FC = () => {
    const [post, setPost] = useState<PostType>();
    const [error, setError] = useState<boolean>(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const loading = !post && !error


    useEffect(() => {
        if (!id || post) return;

        const getPost = async () => {
            try {
                const post = await searchService.getPost(id)
                setPost(post);
                setError(false)
            } catch (error) {
                setError(true)
            }
        }

        getPost()
    }, [id, post])


    return (
        <>
            <MetaTags postId={post?.id} url={`https://shospot.com/post/${post?.id}`} />
            <div tabIndex={-1} aria-hidden="true" className="visible overflow-y-hidden overflow-x-hidden items-center justify-center fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full backdrop-blur-lg">
                <div className=" flex relative p-4 w-auto h-full md:h-auto justify-center items-center">
                    {/* <!-- Modal content --> */}
                    <div className="relative rounded-lg w-fit justify-center self-center shadow">
                        {/* <!-- Modal header --> */}
                        <div className="flex justify-between items-start p-4 rounded-t">
                            <button onClick={() => navigate('/')} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 ease-out duration-300 hover:scale-110 rounded-full text-sm p-1.5 ml-auto inline-flex items-center">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        {/* <!-- Modal body --> */}
                        <div className="p-6 space-y-6">
                            {post && !error && <Post post={post} />}

                            {loading && <UIIndicator contentType={ContentType.LOADING} />}
                        </div>
                        {/* <!-- Modal footer --> */}
                        {/* <div className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                            <button data-modal-toggle="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                            <button data-modal-toggle="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
                        </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default PostScreen