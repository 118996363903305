// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_DATA,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_DATA,
};


// Initialize Firebase
const app = initializeApp(firebaseConfig, "AI");
const firestore = getFirestore(app);

export { firestore, app }