import React from 'react'
import { Post } from '../../types'

type Props = {
    posts: Post[] | null
    onSelectPostIndex: (selectedPostIndex: number) => void,
    selectedPostIndex: number
    disabled: boolean
}

const ImageControls: React.FC<Props> = ({ posts, onSelectPostIndex, selectedPostIndex, disabled }) => {


    const show = posts && posts?.length > 1
    const isSelected = (selectedIndex: number, currentSelectedIndex: number) => {
        return selectedIndex === currentSelectedIndex
    }


    if (!show) return null;

    return (
        <div className={`flex flex-col items-center justify-center lg:mt-20  md:mt-20 mt-10 ${show ? 'vissible scale-y-1 opacity-100' : 'invisible scale-y-0 opacity-20'}`}>

            <div className='imageControl flex flex-col max-h-60 self-center space-y-1 overflow-y-auto py-2 px-1 overflow-x-hidden rounded-xl items-center justify-center'>
                {
                    posts.map((post, index) =>
                        <button key={`${post.id}`} disabled={disabled} className={`w-fit h-fit ease-out duration-300 hover:scale-110 rounded-lg ${isSelected(index, selectedPostIndex) ? 'border-slate-200 border-2' : 'hover:border-slate-200 hover:border-2'} ${disabled ? 'opacity-50 cursor-not-allowed' : "cursor-pointer"}`} onClick={() => onSelectPostIndex(index)}>
                            <img className='rounded-md w-12 h-12' src={post.image} alt={post.description} />
                        </button>
                    )
                }
            </div>

        </div>
    )
}


export default ImageControls