import React from 'react'

type props = {
    title: string,
    infoButtonClick: () => void
    children: React.ReactNode
}
const ImageToolSection: React.FC<props> = ({ title, infoButtonClick, children }) => {
    return (
        <div className='flex self-stretch flex-col space-y-1 border-b border-gray-200 dark:border-gray-700'>
            <div className='flex flex-row items-center mb-2 space-x-1'>
                <h2 className='font-bold text-white'>{title}</h2>

                <button onClick={infoButtonClick} className=' hover:animate-pulse duration-300 ease-in-out transition-transform transform hover:-translate-y-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                </button>
            </div>

            <>
                {children}
            </>

        </div>
    )
}

export default ImageToolSection