import React from 'react'
import { HeaderType } from '../../types'

type ImageTooolRightPanelHeaderProp = {
    selected: HeaderType,
    onSelect: (selected: HeaderType) => void
}
const ImageTooolRightPanelHeader: React.FC<ImageTooolRightPanelHeaderProp> = ({ selected, onSelect }) => {

    const selectedStyle = 'text-white bg-gray-100 active dark:bg-gray-800 dark:text-white'

    const isHeaderSelected = (headerSelected: HeaderType, header: HeaderType) => {
        return headerSelected === header
    }

    return (
        <div className='self-stretch'>
            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                <li className="mr-2">
                    <button onClick={() => onSelect(HeaderType.CREATE)} className={`${isHeaderSelected(selected, HeaderType.CREATE) ? selectedStyle : ''} inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>Create</button>
                </li>


                <li className="mr-2">
                    <button onClick={() => onSelect(HeaderType.ANALYSE)} className={`${isHeaderSelected(selected, HeaderType.ANALYSE) ? selectedStyle : ''} inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>Analyse</button>
                </li>
            </ul>

        </div>
    )

}

export default ImageTooolRightPanelHeader