import React, { useRef } from 'react'
import { RailItem } from '../../types'
import HorizontalScrollButtons from './HorizontalScrollButtons'

type ImageToolRailProp = {
    title: string,
    rail: RailItem[],
    selected: RailItem | null
    onSelect: (railItem: RailItem) => void
}

const ImageToolRail: React.FC<ImageToolRailProp> = ({ title, rail, selected, onSelect }) => {

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const isSelected = (selected: RailItem | null, railItem: RailItem) => {
        //Nothing has been selected yet so keep everything normal
        if (!selected) return true

        return selected.title === railItem.title
    }


    return (
        <div className='flex flex-col relative lg:overflow-x-hidden md:overflow-x-auto sm:overflow-x-auto'>

            <HorizontalScrollButtons scrollContainerRef={scrollContainerRef} />

            <div ref={scrollContainerRef} className='rail flex flex-row items-center space-x-2 px-2 py-2 overflow-x-auto'>
                {
                    rail.map((railItem, index) => {
                        return (
                            <button onClick={() => onSelect(railItem)} key={`rail-${railItem.title}-${index}`} className='min-w-fit h-fit shadow rounded-2xl space-y-1 items-center justify-center duration-300 ease-in-out transition-transform transform hover:-translate-y-2 relative'>
                                <div className='absolute rounded-2xl top-0 left-0 w-24 h-24 bg-black bg-opacity-20 mt-1' />
                                <img className={`w-24 h-24 rounded-2xl object-cover ${!isSelected(selected, railItem) ? 'grayscale filter blur-sm' : ''} transition ease-in-out duration-100`} alt='' src={railItem.image} />
                                <p className='absolute text-xs font-bold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white drop-shadow-2xl shadow-black'>{railItem.title}</p>

                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ImageToolRail