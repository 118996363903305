import React, { useContext } from 'react';
import { BoundryCoords, ObjectsInformation } from "../types";
import { AppContext } from '../context/AppContext'


type TagProps = {
    x: number;
    y: number;
    name: string,
    onClick: () => void
}

const Tag: React.FC<TagProps> = ({ x, y, name, onClick }) => {
    const appContext = useContext(AppContext)

    const tooltipX = (appContext.postSize.width - x) < 100 ? x - 10 : x
    const tooltipY = (appContext.postSize.height - y) < 100 ? x - 10 : y
    return (
        <div className="cursor-pointer" role={"button"} onClick={onClick}>
            <div className="peer object flex h-6 w-6 rounded-full bg-slate-900 z-10 opacity-80 shadow-2xl animate-pulse absolute items-center justify-center cursor-pointer" style={{ left: x, top: y }}>
                <span className=" h-3 w-3 rounded-full bg-white shadow-2xl animate-bounce absolute self-center" />
            </div>
            <span role="tooltip" className="peer-hover:visible invisible absolute text-sm  p-2 font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-80 dark:bg-gray-700" style={{ left: tooltipX - (6 / 2), top: tooltipY + 20 }}>
                {name}
            </span>
        </div>
    )
}

type Props = {
    objects: (ObjectsInformation | undefined)[]
    onClick: (object: ObjectsInformation | undefined) => void
}

const Tags: React.FC<Props> = ({ objects, onClick }) => {
    const appContext = useContext(AppContext)

    const calculateMiddlePoint = (boundryCoord: BoundryCoords) => {
        const x = (boundryCoord.topLeft.x + boundryCoord.topRight.x) / 2
        const y = (boundryCoord.topLeft.y + boundryCoord.topLeft.y) / 2

        return { x: x * (appContext.postSize.width / 512) - 10, y: y * (appContext.postSize.height / 512) + 10 }
    }

    return (
        <div className="pt-2" >
            {
                objects?.map((object, index) => {
                    if (object === undefined || (!object.products || object.products.length === 0)) return null;
                    const coordinates = calculateMiddlePoint(object.boundryCoordinates)
                    return (<Tag key={`${object.name}-${index}`} x={coordinates.x} y={coordinates.y} name={object.name} onClick={() => onClick(object)}></Tag>)
                })
            }
        </div>

    )
}

export default Tags