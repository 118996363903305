import React, { useRef } from 'react'
import { ColourPalette } from '../../types';
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import HorizontalScrollButtons from './HorizontalScrollButtons';

type Props = {
    colourPalette?: ColourPalette[],
    selected: ColourPalette | null,
    onSelect: (colourPalette: ColourPalette | null) => void
}
const ColourPalettes: React.FC<Props> = ({ colourPalette, selected, onSelect }) => {
    const defaultColourPalette = [
        { colour: "#228B22", name: "Forest Green" },
        { colour: "#13294B", name: "Midnight Blue" },
        { colour: "#DDC7A0", name: "Beige" },
        { colour: "#F6909D", name: "Pink" },
        { colour: "#0B0B45", name: "Navy blue" },
        { colour: "#964B00", name: "Brown" },
        { colour: "#C67D1C", name: "Bronze Orange" },
        { colour: "#EF0307", name: "Red" },
        { colour: "#664E88", name: "Purple" },
        { colour: "#FDD808", name: "Yellow" },
        { colour: "#521727", name: "Burgundy" },
        { colour: "#1D1615", name: "Black" },
        { colour: "#E5B723", name: "Gold" },

    ];

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const isSelected = (selected: ColourPalette | null, item: ColourPalette) => {
        //Nothing has been selected yet so keep everything normal
        if (!selected) return true

        return selected.name === item.name
    }

    const listOfPalettes = colourPalette || defaultColourPalette
    return (
        <div className='flex flex-col lg:overflow-x-hidden md:overflow-x-auto sm:overflow-x-auto'>

            <HorizontalScrollButtons scrollContainerRef={scrollContainerRef} />

            <div ref={scrollContainerRef} className='rail flex flex-row space-x-4 py-4 px-2 overflow-x-auto'>
                <button onClick={() => onSelect(null)} className={`flex flex-col items-center space-y-2`}>
                    <NoSymbolIcon className='flex w-10 h-10 rounded-full shadow-2xl border border-solid border-white' />

                    <p className='text-white text-xs w-10 text-center break-words'>None</p>
                </button>

                {
                    listOfPalettes?.map((palette, index) => (
                        <button onClick={() => onSelect(palette)} key={`${palette.name}-${index}`} className={`flex flex-col items-center space-y-2 duration-300 ease-in-out transition-transform transform hover:-translate-y-2`}>
                            <span className={`${!isSelected(selected, palette) ? 'filter blur-sm opacity-90' : ''} flex w-10 h-10 rounded-full shadow-2xl border border-solid border-white`} style={{ backgroundColor: palette.colour }} />
                            <p className='text-white text-xs w-10 text-center self-center break-normal'>{palette.name}</p>
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default ColourPalettes