import React from 'react'
import Lottie from "lottie-react";
import loading from "../lottie/loading.json";

const Loading = () => {

    return <div className='flex flex-col space-y-2'>
        <div className='bg-slate-200 opacity-60 rounded-full'>
            <Lottie className='w-40 h-40 lg:w-60 lg:h-60 md:w-60 md:h-60 opacity-90' animationData={loading} />
        </div>
        <h2 className='font-medium text-center text-slate-400'>Loading...</h2>
    </div>
};

export default Loading;