import React, { useState } from 'react'
import generateTutorial from '../../assets/generating.mp4'
import analyseTutorial from '../../assets/analyse.mp4'
import moreInfoTurorial from '../../assets/more_info.mp4'

import Slide from './Slide'

enum TutorialSlides {
    GENERATE = "generate",
    ANALYSE = "analyse",
    END = "end"
}

type Props = {
    onClose: () => void
}

const TutorialModal: React.FC<Props> = ({ onClose }) => {

    const [currentSlide, setCurrentSlide] = useState<TutorialSlides>(TutorialSlides.GENERATE)

    return (

        <div className='flex flex-col space-y-4 items-center justify-center mt-5'>
            {currentSlide === TutorialSlides.GENERATE && <Slide action={() => setCurrentSlide(TutorialSlides.ANALYSE)} actionTitle="Next" video={generateTutorial} title={"Generate"} description={"Easily create beautiful and realistic interior design images with just a few clicks. Simply select a space, an interior design style, and watch as our robots generate high-quality images."} />}

            {currentSlide === TutorialSlides.ANALYSE && <Slide action={() => setCurrentSlide(TutorialSlides.END)} actionTitle="Next" backAction={() => setCurrentSlide(TutorialSlides.GENERATE)} backActionTitle={'Back'} video={analyseTutorial} title={"Analyse"} description={"With our tool, you can quickly visualize and fine-tune your ideas, making it easier to bring your interior design vision to life. "} />}

            {currentSlide === TutorialSlides.END && <Slide action={() => onClose()} actionTitle="Done" backAction={() => setCurrentSlide(TutorialSlides.ANALYSE)} backActionTitle={'Back'} video={moreInfoTurorial} title={"More help"} description={"The information icon as seen above can be selected for more information for each section"} />}

        </div>

    )
}


export default TutorialModal