import React from 'react'
import { HeaderType, AnalyseToolType, InformationModalData, RailItem, ObjectsInformation, ColourPalette } from '../../types'
import ColourPalettes from './ColourPalette'
import ColourSpectrum from './ColourSpectrum'
import ImageToolRail from './StudioRail'
import ImageTooolRightPanelHeader from './StudioRightPanelHeader'
import ImageToolSection from './StudioSection'
import Products from './Products'

type Props = {
    headerSelected: HeaderType,
    setHeaderSelected: (headerType: HeaderType) => void
    setInformationModal: (informationModalData: InformationModalData | null) => void
    spaceRail: RailItem[]
    spcaeSelected: RailItem | null
    setSelectedSpace: (railItem: RailItem) => void
    styleRail: RailItem[]
    styleSelected: RailItem | null
    setSelectedStyle: (railItem: RailItem) => void
    setColourPalette: (colourPalette: ColourPalette | null) => void
    colourPaletteSelected: ColourPalette | null
    analyseToolSelected: AnalyseToolType,
    dominantColours: string[] | undefined,
    setEnableColourInspector: (enableColourInspector: boolean) => void
    enableColourInspector: boolean,
    colourSpectrum: string[] | undefined,
    selectedObject: ObjectsInformation | undefined,
}


const SidePanelContent: React.FC<Props> = (props) => {
    const {
        headerSelected,
        setHeaderSelected,
        setInformationModal,
        spaceRail,
        spcaeSelected,
        setSelectedSpace,
        styleRail,
        styleSelected,
        setSelectedStyle,
        setColourPalette,
        colourPaletteSelected,
        analyseToolSelected,
        dominantColours,
        colourSpectrum,
        setEnableColourInspector,
        enableColourInspector,
        selectedObject,
    } = props

    return (
        <>
            <ImageTooolRightPanelHeader selected={headerSelected} onSelect={(header) => setHeaderSelected(header)} />

            <div className='flex flex-col space-y-14 pt-10 mb-16'>

                {headerSelected === HeaderType.CREATE && <>

                    {/* Select Space */}
                    <ImageToolSection title={'Space'} infoButtonClick={() => setInformationModal({ title: "Space", description: "Choose the space you want to visualize. For example, you might select a living room." })}>
                        <ImageToolRail title={'Space'} rail={spaceRail} selected={spcaeSelected} onSelect={setSelectedSpace} />
                    </ImageToolSection>


                    {/* Select style */}
                    <ImageToolSection title={'Style'} infoButtonClick={() => setInformationModal({ title: "Style", description: "Choose an interior design style for the image to be displayed in. For example, you might select modern interior design." })}>
                        <ImageToolRail title={'Style'} rail={styleRail} selected={styleSelected} onSelect={setSelectedStyle} />
                    </ImageToolSection>


                    {/* Select colour pallete */}
                    <ImageToolSection title={'Colour Palette'} infoButtonClick={() => setInformationModal({ title: "Colour Palette", description: "Choose a colour and our robots will attempt to include it in the generated images. This is a great way to see how a colour would look in a specific space... or just to annoy the robots by making them do your bidding." })}>
                        <ColourPalettes onSelect={setColourPalette} selected={colourPaletteSelected} />
                    </ImageToolSection>
                </>
                }

                {headerSelected === HeaderType.ANALYSE && <>

                    {analyseToolSelected === AnalyseToolType.COLOUR && <>

                        <ImageToolSection title='Dominant Colours' infoButtonClick={() => setInformationModal({ title: "Dominant Colours", description: "The purpose of this section is to identify the main colors present in the image. This information can be useful for creating a color scheme for your design project." })}>
                            <ColourSpectrum colourSpectrum={dominantColours} />
                        </ImageToolSection>

                        <ImageToolSection title='Colour Spectrum' infoButtonClick={() => setInformationModal({ title: "Colour Spectrum", description: "This section includes a tool that allows you to examine the colors in an image by hovering over the color you want to inspect." })}>

                            <>
                                <div className='ml-2'>
                                    <label className="inline-flex relative items-center cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" checked={enableColourInspector} onChange={() => setEnableColourInspector(!enableColourInspector)} />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-regal-blue"></div>
                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Enable</span>
                                    </label>
                                </div>

                                <ColourSpectrum colourSpectrum={colourSpectrum} />
                            </>
                        </ImageToolSection>
                    </>
                    }


                    {analyseToolSelected === AnalyseToolType.PRODUCTS && <>

                        <ImageToolSection title='Visual Search' infoButtonClick={() => setInformationModal({ title: "Visual Search", description: "This section displays products that have been visually matched to the image. \n This feature us currently disabled" })}>
                            <Products title={selectedObject?.name || ''} products={selectedObject?.products || []} />
                        </ImageToolSection>

                    </>
                    }
                </>
                }

            </div>
        </>
    )
}


export default SidePanelContent