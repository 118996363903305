import React from 'react'

const TermsOfServiceScreen = () => {

    return (

        <div className="flex relative pt-4 w-auto h-full md:h-auto justify-center items-center">
            <div className="relative rounded-lg w-fit h-full justify-center self-center shadow ">
                <div className="flex justify-between items-center p-4 rounded-t">
                    <h1 className="ml-2 font-medium text-center text-slate-400">Terms Of Service</h1>
                </div>

                <div className="p-6 max-w-4xl">
                    <p className="text-slate-300 py-2">These Terms of Service govern your use of Shospot, our website located at <a
                        href="https://shospot.com">https://shospot.com</a>, and any related services provided by
                        Shospot. </p>
                    <p className="text-slate-300 py-2">When you create an Shospot account or use Shospot, you agree to abide by these
                        Terms of
                        Service and to comply with all applicable laws and regulations. If you do not agree with
                        these Terms of Service, you are prohibited from further using the app, accessing our
                        website, or using any other services provided by Shospot. </p>
                    <p className="text-slate-300 py-2">If you access or download Shospot from (1) the Apple App Store, you agree to
                        any Usage Rules
                        set forth in the App Store Terms of Service; and/or (2) the Google Play Store, you agree to
                        the Android, Google Inc. Terms and Conditions including the Google Apps Terms of Service.
                    </p>
                    <p className="text-slate-300 py-2">We, Shospot, reserve the right to review and amend any of these Terms of
                        Service at our sole
                        discretion. Upon doing so, we will update this page and notify you through the app and/or
                        the email address you provided when you created your account. Any changes to these Terms of
                        Service will take effect immediately from the date of publication. </p>
                    <p className="text-slate-300 py-2">These Terms of Service were last updated on 29 October 2021. </p>
                    <h3 className='font-bold text-lg py-2'>Limitations of Use</h3>
                    <p className="text-slate-300 py-2">By using Shospot and our website, you warrant on behalf of yourself, any
                        entity who you
                        represent who has entered into these Terms of Service, and your users that you will not:
                    </p>
                    <ol className="text-slate-300 py-2">
                        <li>modify, copy, prepare derivative works of, decompile, or reverse engineer Shospot or any
                            materials and software contained within Shospot or on our website;</li>
                        <li>remove any copyright or other proprietary notations from Shospot or any materials and
                            software contained within Shospot or on our website;</li>
                        <li>transfer Shospot or any of its associated materials to another person or
                            &ldquo;mirror&rdquo; the materials on any other server;</li>
                        <li>knowingly or negligently use Shospot or any of its associated services in a way that
                            abuses or disrupts our networks or any other service Shospot provides;</li>
                        <li>use Shospot or its associated services to transmit or publish any harassing, indecent,
                            obscene, fraudulent, or unlawful material;</li>
                        <li>use Shospot or its associated services in violation of any applicable laws or
                            regulations;</li>
                        <li>use Shospot to send unauthorised advertising or spam;</li>
                        <li>harvest, collect, or gather user data without the user’s consent; or</li>
                        <li>use Shospot or its associated services in such a way that may infringe the privacy,
                            intellectual property rights, or other rights of third parties.</li>
                    </ol>
                    <h3 className='font-bold text-lg py-2'>Intellectual Property</h3>
                    <p className="text-slate-300 py-2">The intellectual property in the materials in Shospot and on our website are
                        owned by or
                        licensed to Shospot. You may download Shospot to view, use and display the application on
                        your mobile device for your personal use only. </p>
                    <p className="text-slate-300 py-2">This constitutes the grant of a license, not a transfer of title. This license
                        shall
                        automatically terminate if you violate any of these restrictions or these Terms of Service
                        and may be terminated by Shospot at any time. </p>
                    <h3 className='font-bold text-lg py-2'>User-Generated Content</h3>
                    <p className="text-slate-300 py-2">You retain your intellectual property ownership rights over content you submit
                        to us for
                        publication within Shospot and/or on its corresponding website. We will never claim
                        ownership of your content but we do require a license from you in order to use it. </p>
                    <p className="text-slate-300 py-2">When you use Shospot or its associated services to post, upload, share or
                        otherwise transmit
                        content covered by intellectual property rights, you grant to us a non-exclusive,
                        royalty-free, transferable, sub-licensable, worldwide license to use, distribute, modify,
                        run, copy, publicly display, translate or otherwise create derivative works of your content
                        in a manner that is consistent with your privacy preferences and our Privacy Policy. </p>
                    <p className="text-slate-300 py-2">The license you grant us can be terminated at any time by deleting your
                        content or account.
                        However, to the extent that we (or our partners) have used your content in connection with
                        commercial or sponsored content, the license will continue until the relevant commercial or
                        post has been discontinued by us. </p>
                    <p className="text-slate-300 py-2">You give us permission to use your username and other identifying information
                        associated with
                        your account in a manner that is consistent with your privacy preferences and our Privacy
                        Policy. </p>
                    <h3 className='font-bold text-lg py-2'>Automatic Updates</h3>
                    <p className="text-slate-300 py-2">You give us permission to download and install updates to Shospot on your
                        device in
                        accordance with your privacy preferences. This permission can be revoked at any time by
                        deleting Shospot from your device. </p>
                    <h3 className='font-bold text-lg py-2'>Liability</h3>
                    <p className="text-slate-300 py-2">{`Shospot and the materials in Shospot and on our website are provided on an 'as
                        is' basis. To
                        the extent permitted by law, Shospot makes no warranties, expressed or implied, and hereby
                        disclaims and negates all other warranties including, without limitation, implied warranties
                        or conditions of merchantability, fitness for a particular purpose, or non-infringement of
                        intellectual property, or other violation of rights.`}</p>
                    <p className="text-slate-300 py-2">In no event shall Shospot or its suppliers be liable for any consequential
                        loss suffered or
                        incurred by you or any third party arising from the use or inability to use Shospot, our
                        website, or any other services provided by Shospot or the materials in Shospot, even if
                        Shospot or an authorised representative has been notified, orally or in writing, of the
                        possibility of such damage. </p>
                    <p className="text-slate-300 py-2">In the context of this agreement, &ldquo;consequential loss&rdquo; includes
                        any consequential
                        loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue,
                        loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of
                        reputation, loss of use and/or loss or corruption of data, whether under statute, contract,
                        equity, tort (including negligence), indemnity or otherwise. </p>
                    <p className="text-slate-300 py-2">Because some jurisdictions do not allow limitations on implied warranties, or
                        limitations of
                        liability for consequential or incidental damages, these limitations may not apply to you.
                    </p>
                    <h3 className='font-bold text-lg py-2'>Accuracy of Materials</h3>
                    <p className="text-slate-300 py-2">The materials appearing in Shospot or on our website are not comprehensive and
                        are for
                        general information purposes only. To the extent permitted by law, Shospot does not warrant
                        or make any representations concerning the accuracy, likely results, or reliability of the
                        use of the materials in Shospot or on our website, or otherwise relating to such materials
                        or on any resources linked to Shospot and our website. </p>
                    <h3 className='font-bold text-lg py-2'>Links</h3>
                    <p className="text-slate-300 py-2">Shospot has not reviewed all of the sites linked to Shospot or on its
                        corresponding website
                        and is not responsible for the contents of any such linked site. The inclusion of any link
                        does not imply endorsement, approval or control by Shospot of the site. Use of any such
                        linked website is at your own risk and we strongly advise you make your own investigations
                        with respect to the suitability of those sites. </p>
                    <h3 className='font-bold text-lg py-2'>Notice regarding Apple</h3>
                    <p className="text-slate-300 py-2">To the extent that you are using or accessing Shospot on an iOS device, you
                        acknowledge and
                        agree to the terms of this clause. You acknowledge that these Terms of Service are between
                        you and Shospot only, not with Apple Inc. (Apple), and Apple is not responsible for Shospot
                        and any materials available in Shospot. </p>
                    <p className="text-slate-300 py-2">Apple has no obligation to furnish you with any maintenance and support
                        services with respect
                        to Shospot. </p>
                    <p className="text-slate-300 py-2">If Shospot fails to conform to any applicable warranty, you may notify Apple
                        and Apple will
                        refund the purchase price of the mobile application to you. To the maximum extent permitted
                        by applicable law, Apple will have no other warranty obligation whatsoever with respect to
                        Shospot and any other claims, losses, liabilities, damages, costs or expenses attributable
                        to any failure to conform to any warranty will be our responsibility. </p>
                    <p className="text-slate-300 py-2">Apple is not responsible for addressing any claims by you or any third party
                        relating to
                        Shospot or your use of Shospot, including but not limited to (1) product liability claims;
                        (2) any claim that our mobile application fails to conform to any applicable legal or
                        regulatory requirement; and (3) claims arising under consumer protection or similar
                        legislation. </p>
                    <p className="text-slate-300 py-2">Apple is not responsible for the investigation, defence, settlement and
                        discharge of any
                        third-party claim that our mobile application infringes that third party’s intellectual
                        property rights. </p>
                    <p className="text-slate-300 py-2">You agree to comply with any applicable third-party terms when using Shospot,
                        including any
                        Usage Rules set forth in the Apple App Store Agreement of Service. </p>
                    <p className="text-slate-300 py-2">Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms of
                        Service and,
                        upon your acceptance of these Terms of Service, Apple will have the right (and will be
                        deemed to have accepted the right) to enforce these Terms of Service against you as a
                        third-party beneficiary of these Terms of Service. </p>
                    <p className="text-slate-300 py-2">{`You hereby represent and warrant that (1) you are not located in a country
                        that is subject to
                        a U.S. Government embargo, or that has been designated by the U.S. Government as a
                        "terrorist supporting" country; and (2) you are not listed on any U.S. Government list of
                        prohibited or restricted parties.`}</p>
                    <h3 className='font-bold text-lg py-2'>Right to Terminate</h3>
                    <p className="text-slate-300 py-2">We may suspend or terminate your Shospot account and right to use Shospot and
                        these Terms of
                        Service immediately upon written notice to you for any breach of these Terms of Service.
                    </p>
                    <h3 className='font-bold text-lg py-2'>Severance</h3>
                    <p className="text-slate-300 py-2">Any term of these Terms of Service which is wholly or partially void or
                        unenforceable is
                        severed to the extent that it is void or unenforceable. The validity of the remainder of
                        these Terms of Service is not affected. </p>
                    <h3 className='font-bold text-lg py-2'>Governing Law</h3>
                    <p className="text-slate-300 py-2">These Terms of Service are governed by and construed in accordance with the
                        laws of United
                        Kingdom. You irrevocably submit to the exclusive jurisdiction of the courts in that State or
                        location. </p>
                </div>
            </div>
        </div>
    )
}

export default TermsOfServiceScreen


