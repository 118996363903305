import React from 'react';
import { ColorsInformation } from "../types"

type Props = {
    colors: (ColorsInformation | undefined)[]
}

const Colors: React.FC<Props> = ({ colors }) => {

    return (
        <div className="relative" >
            <div className='flex flex-row border justify-end whitespace-nowrap border-slate-300 rounded-lg h-fit shadow-xl overflow-hidden'>
                {
                    colors?.map((color, index) => {
                        return (
                            <div className="flex flex-row" key={`${color?.hex}-${index}`}>
                                <span className="peer" style={{ width: color?.percentage, height: 40, backgroundColor: color?.hex }} />
                                <span role="tooltip" className="peer-hover:visible invisible absolute left-48 top-1 text-sm -ml-20  p-2 font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-80 dark:bg-gray-700" > {color?.hex} </span>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    )
}

export default Colors