import React from 'react'



type Props = {
    show: boolean
    message?: string
}

const Toast: React.FC<Props> = ({ show, message }) => {

    if (!show) return null;

    return (
        <div style={{ left: '50%', top: '50%', marginLeft: -(200 / 2), width: 200 }} className={'flex whitespace-nowrap  absolute p-2 right-0 -mt-5 justify-center self-center text-white bg-regal-blue rounded-xl shadow-sm opacity-80 transition-all duration-300 ease-in-out items-center'} >
            <span className="w-auto text-white">{message}</span>
        </div>
    )
}

export default Toast