import React, { useEffect, useState } from 'react'
import { useHits, useInstantSearch } from 'react-instantsearch-hooks-web';
import Post from './Post';
import { Post as PostType } from '../types'

import UIIndicator, { ContentType } from './UIIndicator';
import { getLikes } from '../services/likes';

type Props = {
    searching?: boolean
}

const SearchHits: React.FC<Props> = ({ searching }) => {
    const { hits } = useHits();
    const { status } = useInstantSearch();
    const [posts, setPosts] = useState<PostType[]>([])


    const transformType = async (hit: any): Promise<PostType> => {
        const likes = await getLikes(hit.id)
        return {
            id: hit.id,
            title: hit.title,
            description: hit.description,
            image: hit.image,
            seed: hit.seed,
            mimeType: hit.mimeType,
            dateCreated: hit.dateCreated,
            metaData: hit.metaData,
            likes: likes
        }
    }

    const loading = status === "stalled" || status === "loading"

    let contentType;
    if (loading) {
        contentType = ContentType.LOADING
    }
    else if (!loading && searching && hits.length === 0) {
        contentType = ContentType.EMPTY_SEARCH_RESULTS
    }

    useEffect(() => {
        const enrichHitWithLikes = async () => {
            const resolvedResults = await Promise.all(hits.map(async (hit: any) => {
                return transformType(hit)
            }))

            setPosts(resolvedResults)
        }

        enrichHitWithLikes();
    }, [hits])

    if (!searching) return null

    return (
        <>
            {searching && hits.length === 0 && <UIIndicator contentType={contentType} />}

            {posts.map((hit, index) => {
                return (
                    <Post key={`${hit.id}-${index}`} post={hit} />
                )
            })}
        </>
    )
}

export default SearchHits