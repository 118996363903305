export const createColorSpectrum = (startHex: string, steps: number[]): string[] | undefined => {
    // Convert hex code to RGB values
    const startRgb = hexToRgb(startHex);

    if (!startRgb) return;
    // Create an array of color codes
    const colors: string[] = [];
    for (const step of steps) {
        // Calculate the RGB values for the current color
        const r = startRgb.r + Math.round(255 * step);
        const g = startRgb.g + Math.round(255 * step);
        const b = startRgb.b + Math.round(255 * step);

        // Convert the RGB values to a hex code and add it to the array
        colors.push(rgbToHex(r, g, b));
    }

    return colors;
}

// Converts a hex code to RGB values
export function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

// Converts RGB values to a hex code
export function rgbToHex(r: number, g: number, b: number): string {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}


export function rgbStringToHex(rgb: string): string {
    const [r, g, b] = rgb.substring(4, rgb.length - 1).split(',').map((x: string) => parseInt(x, 10));
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');
    return `#${rHex}${gHex}${bHex}`;
}


