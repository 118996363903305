import React, { useState } from 'react'
import welcomeImage from '../../assets/living_room.jpg'
import welcomeImage2 from '../../assets/welcome_image_2.jpg'
import welcomeImage3 from '../../assets/small_living_room.jpg'

import Slide from './Slide'

enum WelcomeSlides {
    WELCOME = "welcome",
    DISCLAIMER = "disclaimer",
    END = "end"
}

type Props = {
    onClose: () => void
}


const WelcomeModal: React.FC<Props> = ({ onClose }) => {

    const [currentSlide, setCurrentSlide] = useState<WelcomeSlides>(WelcomeSlides.WELCOME)

    return (

        <div className='flex flex-col space-y-4 items-center justify-center mt-5'>
            {currentSlide === WelcomeSlides.WELCOME && <Slide action={() => setCurrentSlide(WelcomeSlides.DISCLAIMER)} actionTitle="Next" image={welcomeImage} title={"Effortless interior visualization with AI"} description={"Easily create beautiful and realistic interior design images with just a few clicks. Simply select a space, an interior design style, and watch as our robots generate high-quality images."} />}

            {currentSlide === WelcomeSlides.DISCLAIMER && <Slide action={() => setCurrentSlide(WelcomeSlides.END)} actionTitle="Next" image={welcomeImage2} title={"A tool to inspire creativity and generate ideas!"} description={"With our tool, you can quickly visualize and fine-tune your ideas, making it easier to bring your interior design vision to life. "} />}

            {currentSlide === WelcomeSlides.END && <Slide action={() => onClose()} actionTitle="Lets Go" image={welcomeImage3} title={"Enjoy!"} description={"Time to boost your creative design process with our robots at your side!"} />}

        </div>

    )
}


export default WelcomeModal