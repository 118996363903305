import React from 'react'
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation()

    if (location.pathname.startsWith('/studio')) return null

    return (
        <footer className="flex self-end bottom-0 left-0 w-full items-center justify-center p-4 backdrop-blur-3xl backdrop-saturate-50 bg-regal-blue opacity-80 z-30 px-6">
            <ul className="flex flex-wrap items-center text-center text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
                <li>
                    <a href="/privacy-policy" className="mr-4 hover:underline text-xs">Privacy Policy</a>
                </li>
                <li>
                    <a href="/terms-of-service" className="mr-4 hover:underline text-xs">Terms of Service</a>
                </li>
                <li>
                    <a href="https://www.instagram.com/shospot_app" className="mr-4 hover:underline text-xs">Instagram</a>
                </li>
            </ul>
        </footer>

    )
}

export default Footer