import React, { useContext, useEffect, useState } from 'react'
import { Product } from '../../types'
import ProductPlaceholder from '../ProductPlaceholder'
import ProductCard from '../ProductCard';
import * as searchService from "../../services/search"
import comingSoon from '../../assets/coming_soon.gif'
import { AppContext } from '../../context/AppContext';

type Props = {
    products: string[] | null
    title: string
}

//TODO - Needs work
const Products: React.FC<Props> = ({ products, title }) => {
    const [loading, setLoading] = useState(false);
    const [prodcutsData, setProductsData] = useState<Product[]>([]);

    const appContext = useContext(AppContext)
    useEffect(() => {

        if ((!products || products.length === 0) || (products[0] === prodcutsData[0].id) || loading) return;

        const getProducts = async () => {
            setLoading(true)
            const productsResult = await searchService.getProductsByIds(products);
            setProductsData(productsResult)
            setLoading(false)
        }
        getProducts()
    }, [loading, prodcutsData, prodcutsData.length, products])

    return (
        <div className="flex flex-col space-y-4 pb-4 items-center h-full w-full">
            <div className='flex px-4 self-stretch justify-between'>
                <span className='flex self-start font-medium text-white-500'>{title}</span>
                <div className='flex space-x-2 items-center justify-center'>
                    <span className='flex font-medium text-gray-500'>Count:</span>
                    <span className='flex text-sm w-5 h-5 rounded-lg bg-slate-200 bg-opacity-50 items-center justify-center text-center font-medium text-gray-500'>{products?.length}</span>
                </div>
            </div>

            {!loading &&
                prodcutsData?.map((product) => <ProductCard key={product.id} product={product} />)
            }

            {
                loading && <>
                    <ProductPlaceholder />
                    <ProductPlaceholder />
                    <ProductPlaceholder />
                </>
            }

            {/* coming soon image */}
            {products?.length === 0 && !loading && <>

                <img className='rounded-md' src={comingSoon} alt='product search coming soon' />

                <button className="self-center mt-4 w-fit ease-out duration-300 hover:scale-110 hover:text-white whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-lg px-7 py-2" onClick={() => {
                    appContext.setShowAuthModal(true)
                }}>{"Join the waiting list"}</button>
            </>
            }

        </div >
    )
}

export default Products