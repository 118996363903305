import React from "react"
import { AnalyseToolType } from "../../types"

type Props = {
    show: boolean
    selected: AnalyseToolType
    onSelect: (analyseToolType: AnalyseToolType) => void
}

const ToolMenu: React.FC<Props> = ({ show, selected, onSelect }) => {
    const selectedStyle = 'text-white border-slate-600 dark:text-slate-300 dark:border-slate-500'

    const isToolSelected = (toolSelected: AnalyseToolType, tool: AnalyseToolType) => {
        return toolSelected === tool
    }

    // const
    return (
        <div className={`lg:visible invisible hidden lg:flex lg:flex-col border-b border-gray-200 dark:border-gray-700 self-end items-end ${show ? ' scale-y-1 opacity-100' : 'scale-y-0 opacity-20'} duration-300 ease-in-out transition-transform transform`}>
            <div className="inline-flex self-start mr-2 p-4 text-md font-light items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300">
                    <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 016.775-5.025.75.75 0 01.313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 011.248.313 5.25 5.25 0 01-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 112.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0112 6.75zM4.117 19.125a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clipRule="evenodd" />
                    <path d="M10.076 8.64l-2.201-2.2V4.874a.75.75 0 00-.364-.643l-3.75-2.25a.75.75 0 00-.916.113l-.75.75a.75.75 0 00-.113.916l2.25 3.75a.75.75 0 00.643.364h1.564l2.062 2.062 1.575-1.297z" />
                    <path fillRule="evenodd" d="M12.556 17.329l4.183 4.182a3.375 3.375 0 004.773-4.773l-3.306-3.305a6.803 6.803 0 01-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 00-.167.063l-3.086 3.748zm3.414-1.36a.75.75 0 011.06 0l1.875 1.876a.75.75 0 11-1.06 1.06L15.97 17.03a.75.75 0 010-1.06z" clipRule="evenodd" />
                </svg>

                Tools
            </div>

            <ul className="flex flex-col flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 items-start">
                <li className="mr-2">
                    <button onClick={() => onSelect(AnalyseToolType.COLOUR)} className={`inline-flex ${isToolSelected(selected, AnalyseToolType.COLOUR) ? selectedStyle : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'} p-4 rounded-t-lg group`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${isToolSelected(selected, AnalyseToolType.COLOUR) ? 'text-white' : 'text-gray-400 group-hover:text-white'} mr-2 w-5 h-5`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                        </svg>
                        Colour
                    </button>
                </li>
                <li className="mr-2">
                    <button onClick={() => onSelect(AnalyseToolType.PRODUCTS)} className={`inline-flex ${isToolSelected(selected, AnalyseToolType.PRODUCTS) ? selectedStyle : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'} p-4 rounded-t-lg group`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`${isToolSelected(selected, AnalyseToolType.PRODUCTS) ? 'text-white' : 'text-gray-400 group-hover:text-white'} mr-2 w-5 h-5`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                        </svg>
                        Products
                    </button>
                </li>
            </ul>
        </div>
    )
}


export default ToolMenu