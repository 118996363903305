import React from "react";



export enum SCREEN_SIZE {
    LARGE = "Large",
    SMALL = "Small",
    EXTRA_SMALL = "ExtraSmall"
}

export const postSizeLarge = { width: 512, height: 512 };
export const psotSizeSmall = { width: 320, height: 320 };
export const postSizeXSmall = { width: 300, height: 300 };
export const screenSize = SCREEN_SIZE.LARGE


const DEAFULT_CONTEXT = {
    postSize: postSizeLarge,
    screenSize: screenSize,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShowAuthModal: (show: boolean) => { },
}

export const AppContext = React.createContext(DEAFULT_CONTEXT);