import React from 'react'

type Props = {
    show: boolean
    onClose: () => void
    children: React.ReactNode
}

const SidePanelMobile: React.FC<Props> = ({ show, onClose, children }) => {
    // ease-in-out
    return (
        <div tabIndex={-1} aria-hidden="true" onClick={() => onClose()} className={`flex ${show ? 'translate-x-0' : 'translate-x-full'} visible overflow-y-auto overflow-x-hidden absolute top-0 right-0 left-0 w-full h-full md:inset-0 h-modal backdrop-blur-sm items-center justify-center"`}>
            <div onClick={(event) => event.stopPropagation()} className={`lg:invisible visible absolute z-40 h-screen bottom-0 right-0 p-4 md:mb-10 overflow-y-auto rounded w-80 md:w-96 transform ease-in-out transition-all duration-300 ${show ? 'translate-x-0' : 'translate-x-full'} bg-slate-600 backdrop-blur-lg backdrop-opacity-30`} tabIndex={-1}>
                <div className="flex justify-between items-start rounded-t">
                    <button onClick={() => onClose()} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 ease-out duration-300 hover:scale-110 rounded-full text-sm p-1.5 ml-auto inline-flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>

                {children}
            </div>
        </div>
    )
}

export default SidePanelMobile