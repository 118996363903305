import React from 'react'
import Lottie from "lottie-react";
import aiRobot from "../lottie/aiRobot.json";

const AIRobot = () => {

    return <div className='flex flex-row bg-white rounded-full self-center my-3'>
        <Lottie className='w-24 h-24' animationData={aiRobot} width={100} height={100} />
    </div>
};

export default AIRobot;