import chroma from "chroma-js";

// This function generates a monochromatic color palette
// based on the given color
export function generateMonochromaticPalette(color: string): string[] {
    // Convert the color to HSL format
    const hslColor = chroma(color).lab();
    // The base color will be the first color in the palette
    const palette = [color];

    // Generate the rest of the palette by adjusting the lightness
    // of the base color
    for (let i = 1; i < 5; i++) {
        // Adjust the lightness by 10% each time
        const adjustedColor = chroma.lab(hslColor[0] - (i * 10), hslColor[1], hslColor[2]).hex();
        palette.push(adjustedColor);
    }

    return palette;
}