import React from 'react'
import './Slider.css';

//Make this of type T genereic
type Props = {
    values: number[],
    onChange: (value: number) => void
    currentValue: number,
    disabled: boolean
}

const Slider: React.FC<Props> = ({ values, onChange, currentValue, disabled }) => {

    return (
        <div className='px-2 flex flex-row items-center justify-center space-x-2'>

            <span className='flex text-sm w-5 h-5 rounded-lg bg-slate-200 bg-opacity-20 items-center justify-center text-center font-medium text-slate-400 self-end'>{currentValue}</span>

            <input id="range" type="range" disabled={disabled} step={1} max={values.length} min={1} list={"values"} value={currentValue} onChange={(event) => onChange(Number(event.currentTarget.value))} className=" w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />

            <datalist id='values' className='flex-row justify-between hidden'>
                {
                    values.map((value) => <option value={value} key={`${value}}`} />)
                }
            </datalist>
        </div>
    )
}


export default Slider