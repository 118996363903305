import React from 'react'
import Lottie from "lottie-react";
import error from "../lottie/error.json";
import LoadingButton from './LoadingButton';


type Props = {
    tryAgain?: () => void
    loading?: boolean
}
const Error: React.FC<Props> = ({ tryAgain, loading }) => {
    return <div className='flex flex-col space-y-2 items-center'>
        <div className='bg-slate-200 opacity-60 rounded-full'>
            <Lottie className='w-40 h-40 lg:w-60 lg:h-60 md:w-60 md:h-60 opacity-90' animationData={error} />
        </div>

        <h2 className='font-medium text-center text-slate-400'> Oops! something went wrong</h2>

        {tryAgain && <LoadingButton loading={!!loading} title={'Try again..'} onClick={() => tryAgain()} />}
    </div>
};

export default Error;