import React, { useContext } from 'react';
import { HeartIcon, AdjustmentsHorizontalIcon, QueueListIcon } from '@heroicons/react/24/solid'
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';


const Nav: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const useFixedNav = location.pathname.startsWith('/studio')
    const appContext = useContext(AppContext)

    const isSelected = (navItem: string) => {
        if (location.pathname === navItem) {
            return true
        }
        return location.pathname.startsWith(`/${navItem}`)
    }

    return (
        <>
            <nav id="header" className={`w-full ${useFixedNav ? 'fixed' : 'sticky'} backdrop-blur-3xl backdrop-saturate-50 bg-regal-blue opacity-80 z-30 top-0`}>
                <div className="w-full container mx-auto flex flex-row items-center justify-between lg:px-6 px-3 py-2">

                    <div className="flex flec-row w-auto space-x-2 items-center justify-center">
                        <a className="flex items-center tracking-wide no-underline hover:no-underline font-bold text-white lg:text-xl md:text-lg text-sm whitespace-nowrap" href="/">
                            Shospot
                        </a>
                        <a className="flex animate-pulse items-center tracking-wide no-underline hover:no-underline font-normal text-slate-300 lg:text-sm md:text-sm text-sm whitespace-nowrap" href="/">
                            Beta
                        </a>
                    </div>

                    <div className="flex flex-row space-x-2">
                        <button className="order-2 md:order-3 flex flex-col items-center justify-center w-fit" id="nav-content" onClick={() => navigate('/')}>
                            <div className="ease-out duration-300 hover:scale-110 hover:text-white  w-auto whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-full px-2 py-2">
                                <QueueListIcon className="fill-current text-white hover:text-white" width="12" height="12" />
                            </div>
                            <span className={`text-xs ${isSelected('/') ? 'text-white font-bold' : 'text-slate-300'}`}>feed</span>
                        </button>


                        <button className="order-2 md:order-3 flex flex-col items-center justify-center w-fit" id="nav-content" onClick={() => navigate('/studio')}>
                            <div className="ease-out duration-300 hover:scale-110 hover:text-white  w-auto whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-full px-2 py-2">
                                <AdjustmentsHorizontalIcon className="fill-current text-white hover:text-white" width="12" height="12" />
                            </div>
                            <span className={`text-xs ${isSelected('studio') ? 'text-white font-bold' : 'text-slate-300'}`}>studio</span>
                        </button>

                        <button className="order-2 md:order-3 flex flex-col items-center justify-center" id="nav-content" onClick={() => navigate('/likes')}>
                            <div className="ease-out duration-300 hover:scale-110 hover:text-white  w-auto whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-full px-2 py-2" >
                                <HeartIcon className="fill-current text-white hover:text-white" width="12" height="12" />
                            </div>
                            <span className={`text-xs ${isSelected('likes') ? 'text-white font-bold' : 'text-slate-300'}`}>likes</span>
                        </button>


                        <button className="order-2 md:order-3 flex flex-col items-center justify-center" id="nav-content" onClick={() => appContext.setShowAuthModal(true)}>
                            <div className="ease-out duration-300 hover:scale-110 hover:text-white  w-auto whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-full px-2 py-2">
                                <svg className="fill-current text-white hover:text-white" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
                                    <circle fill="none" cx="12" cy="7" r="3" />
                                    <path d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5S14.757 2 12 2zM12 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 10 12 10zM21 21v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1H21z" />
                                </svg>
                            </div>
                            <span className={`text-xs text-slate-300`}>account</span>

                        </button>
                    </div>
                </div>
            </nav>
        </>
    )

}

export default Nav