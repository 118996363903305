import { app } from "../firebase/initFirebase";
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from "firebase/app-check";

const appCheck = initializeAppCheck(
    app,
    { provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY || "") }
);

export const getAppCheckToken = async (): Promise<string | undefined> => {
    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        return;
    }

    return appCheckTokenResponse.token
};

