export function getStorageValue(key: string, defaultValue: any) {
    // getting stored value
    const saved = localStorage.getItem(key);
    if (!saved) return defaultValue

    const initial = JSON.parse(saved);
    return initial
}

export function setStorageValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}