import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Studio from './components/studio/Studio';
import { Post } from './types';
import * as searchService from './services/search'

//Do something with the error!!
const StudioScreen: React.FC = () => {
    const [post, setPost] = useState<Post | undefined>(undefined);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams();




    useEffect(() => {
        if (!id || post) return;

        const getPost = async () => {
            try {
                setLoading(true)
                const post = await searchService.getPost(id)
                console.log(post)
                setPost(post);
                setError(false)
                setLoading(false)
            } catch (error) {
                setError(true)
                setLoading(false)
            }
        }

        getPost()
    }, [id, post])



    return (
        <div className='w-screen h-[calc(100vh-60px)] overflow-hidden' style={{ marginTop: '60px' }}>
            {!loading && <Studio post={post} />}
        </div>
    )
}

export default StudioScreen