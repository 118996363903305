declare global {
    interface Window {
        FIREBASE_APPCHECK_DEBUG_TOKEN: boolean;
    }
    let __DEV__: boolean
}


export type Coordinates = {
    x: number;
    y: number;
}

export type Timestamp = {
    seconds: number,
    nanoseconds: number
}

export type BoundryCoords = {
    topLeft: Coordinates;
    topRight: Coordinates
    bottomRight: Coordinates
    bottomLeft: Coordinates
}

export type ObjectsInformation = {
    name: string
    score: number,
    mid: string,
    vertices: Coordinates[],
    boundryCoordinates: BoundryCoords,
    croppedImage: string | null
    products: string[] | null
}

export type PostMetaData = {
    objects: (ObjectsInformation | undefined)[],
    labels: (string | null)[],
    colors: (ColorsInformation | undefined)[],
}

export type Color = {
    alpha: number | null,
    blue: number,
    green: number,
    red: number
}

export type ColorsInformation = {
    color?: (Color | null);
    score?: (number | null);
    pixelFraction?: (number | null);
    percentage: number
    hex: string
}

export type Task = {
    filepath: string,
    postId: string
}

export type Likes = {
    likesCount: number
}

export type Post = {
    id: string
    title: string,
    description: string,
    image: string,
    seed: number,
    mimeType: string,
    dateCreated: Timestamp | number | null,
    metaData: PostMetaData,
    likes: Likes
}
export type Product = {
    id: string | null,
    title: string | null,
    link: string | null,
    image: string | null,
    price: string | null,
    brand: string | null,
    postId: string | null,
    localisation: Locale[] | null,
    dateCreated: Timestamp | null
}

export type Locale = {
    locale: string
}

export type GenerateImageOptions = Partial<{
    outDir: string;
    debug: boolean;
    requestId: string;
    samples: number;
    engine: string;
    host: string;
    seed: number;
    width: number;
    height: number;
    diffusion: "ddim" | "plms" | "k_euler" | "k_euler_ancestral" | "k_heun" | "k_dpm_2" | "k_dpm_2_ancestral" | "k_lms";
    steps: number;
    cfgScale: number;
    noStore: boolean;
    imagePrompt: { mime: string; content: Buffer; } | null;
    stepSchedule: { start?: number | undefined; end?: number | undefined; };
}>
    & { apiKey: string; prompt: string; };

export type ObjectsMapping = { [key: string]: string[] | (string | string[])[] }

export enum LikeAction {
    LIKE = "like",
    UNLIKE = "unlike"
}

export type RailItem = {
    image: string
    title: string
}

export enum HeaderType {
    CREATE = "create",
    ANALYSE = "anlyse"
}

export enum AnalyseToolType {
    COLOUR = "colour",
    PRODUCTS = "products"
}

export type InformationModalData = { title: string, description: string }

export type ColourPalette = { colour: string, name: string }

export type GenerateImageParams = {
    space: string,
    style: string,
    colourPalette: string | null,
    numberOfImage: number,
    random: boolean
}