import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { XCircleIcon } from "@heroicons/react/24/solid";
import { Product } from "../types";
import ProductPlaceholder from "./ProductPlaceholder";
import ProductTag from './ProductCard';
import * as searchService from "../services/search"

type Props = {
    products: string[] | null
    show: boolean
    onClose: () => void
}

const ProductsPanel: React.FC<Props> = ({ products, show, onClose }) => {
    const appContext = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    const [prodcutsData, setProductsData] = useState<Product[]>([]);

    useEffect(() => {

        if (!products || products.length === 0 || prodcutsData.length !== 0) return;
        const getProducts = async () => {
            setLoading(true)
            const productsResult = await searchService.getProductsByIds(products);
            setProductsData(productsResult)
            setLoading(false)
        }
        getProducts()
    }, [prodcutsData.length, products])

    return (
        <div className={`flex z-10 flex-col my-1 bg-white absolute left-0 bottom-0 transition-all duration-500 rounded-xl p-4 ease-bounce overflow-hidden`} style={{ width: appContext.postSize.width, height: "auto", verticalAlign: 'bottom', bottom: show ? 0 : -600 }}>
            <XCircleIcon className="flex self-end shadow-transparent rounded-full justify-center cursor-pointer text-gray-600 w-8 h-8" role={"button"} onClick={() => {
                setProductsData([])
                onClose()
            }} />

            <div className={'flex flex-col justify-between mx-1'}>
                <span className='font-bold text-neutral-600'>{'Visual search'}</span>
            </div>


            <div className="flex flex-row items-center overflow-hidden overflow-x-auto h-full w-full" style={{ minHeight: 300 }}>
                {!loading &&
                    prodcutsData?.map((product) => <ProductTag key={product.id} product={product} />)
                }

                {
                    loading && <>
                        <ProductPlaceholder />
                        <ProductPlaceholder />
                        <ProductPlaceholder />
                    </>
                }

            </div>
        </div>

    )
}

export default ProductsPanel