import React, { useLayoutEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppContext, postSizeLarge, psotSizeSmall, postSizeXSmall, SCREEN_SIZE } from './context/AppContext';
import PostScreen from './PostScreen';
import HomeScreen from './HomeScreen';
import Nav from './components/Nav';
import MetaTags from './components/MetaTags';
import LikesScreen from './LikesScreen';
import StudioScreen from './StudioScreen';
import AuthModal from './components/AuthModal';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';
import TermsOfServiceScreen from './TermsOfServiceScreen';
import Footer from './components/Footer';


const App = () => {
  const [postSize, setPostSize] = useState<{ width: number, height: number }>(postSizeLarge)
  const [screenSize, setScreenSize] = useState<SCREEN_SIZE>(SCREEN_SIZE.LARGE);
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false)

  useLayoutEffect(() => {

    resizeComponent(setPostSize, setScreenSize);

    window.addEventListener('resize', () => {
      resizeComponent(setPostSize, setScreenSize);
    })
  }, [])

  function resizeComponent(setPostSize: React.Dispatch<React.SetStateAction<{ width: number; height: number; }>>, setScreenSize: React.Dispatch<React.SetStateAction<SCREEN_SIZE>>) {
    if (window.screen.availWidth <= 768) {
      setPostSize(psotSizeSmall);
      setScreenSize(SCREEN_SIZE.SMALL);
    }

    if (window.screen.availWidth <= 320) {
      setPostSize(postSizeXSmall);
      setScreenSize(SCREEN_SIZE.EXTRA_SMALL);
    }

    if (window.screen.availWidth > 768) {
      setPostSize(postSizeLarge);
      setScreenSize(SCREEN_SIZE.LARGE);
    }
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ postSize, screenSize, setShowAuthModal }}>
        <div className='App-header bg-gradient-to-r from-regal-blue to-regal-blue2'>
          <MetaTags />
          <Nav />
          <AuthModal showModal={showAuthModal} onClose={() => setShowAuthModal(false)} />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/post/:id" element={<PostScreen />} />
            <Route path="/likes" element={<LikesScreen />} />
            <Route path="/studio" element={<StudioScreen />} />
            <Route path="/studio/:id" element={<StudioScreen />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route path="/terms-of-service" element={<TermsOfServiceScreen />} />
          </Routes>
          <Footer />
        </div>
      </AppContext.Provider>
    </BrowserRouter >
  );
}

export default App;


