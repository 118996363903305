import React from 'react'
import Lottie from "lottie-react";
import robot from "../../lottie/robotWorking.json";

type Props = {
    show: boolean
}
const ToolLoading: React.FC<Props> = ({ show }) => {

    const facts =
        [
            "The colour blue is often used in interior design because it is calming and promotes relaxation.",
            "The Golden Ratio, a mathematical ratio found in nature, is often used in interior design to create aesthetically pleasing compositions.",
            "Mirrors are often used in interior design to make a space feel larger and more open.",
            "Natural light is important in interior design because it helps to create a sense of warmth and comfort.",
            "The use of plants in interior design is believed to improve air quality and create a more relaxing environment.",
            "The use of texture in interior design can help to create visual interest and add depth to a space.",
            "Incorporating pops of colour through accessories, such as throw pillows and artwork, is a simple way to update the look of a room.",
            "A room with a fireplace can feel more welcoming and comfortable, making it a popular feature in home design.",
            "Good lighting design is important in interior design because it can create a desired mood and highlight certain features of a space.",
            "Mixing and matching different styles, such as modern and traditional, can create a unique and interesting interior design.",
            "Warm colours, such as red and yellow, can make a space feel cozy and inviting.",
            "Neutral colours, such as beige and white, can make a space feel calm and relaxing.",
            "Rugs can add colour and pattern to a room, as well as provide insulation and sound absorption.",
            "Wallpaper can add texture and pattern to a room, and is available in a wide range of styles and colours.",
            "Window treatments, such as curtains and blinds, can add privacy and control the amount of light in a room.",
            "Furniture placement can affect the flow and functionality of a room.",
            "A room with high ceilings can appear larger and more spacious.",
            "A well-placed piece of artwork can serve as a focal point in a room.",
            "Throws and pillows can add colour and texture to a room and make it feel more comfortable.",
            "A room with a view can feel more open and inviting.",
        ]

    if (!show) return null;


    return (
        <div className='top-0 left-0 absolute bg-regal-blue backdrop-filter backdrop-blur-md bg-opacity-30 w-full h-full rounded-md'>
            <div className='flex flex-col opacity-80 items-center justify-center h-full  w-full '>


                <Lottie style={{ width: 280 }} animationData={robot} />
                <p className='font-thin text-center text-white animate-pulse'>The robots are diligently creating an image!</p>

                <p className='font-medium text-center text-slate-100 px-4 py-2'>{facts[Math.floor(Math.random() * facts.length)]}</p>

            </div>
        </div>
    )
}

export default ToolLoading

