import React from 'react'

type Props = {
  loading: boolean
  title: string
  onClick: () => void
}

const LoadingButton: React.FC<Props> = ({ loading, title, onClick }) => {
  return (
    <button type="button" onClick={() => onClick()} className="bg-slate-300 hover:bg-regal-blue w-fit hover:border-white hover:border-2  hover:text-white p-5 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-3xl text-regal-blue transition ease-in-out duration-100">
      {loading &&
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>}
      {title}
    </button>
  )
}

export default LoadingButton