import React, { useEffect, useState } from 'react'

type Props = {
  show: boolean
}

const ProgressCircle: React.FC<Props> = ({ show }) => {

  const [progress, setProgress] = useState(0);
  const dasharray = 2 * Math.PI * 30;
  const dashoffset = (1 - (progress / 100)) * dasharray;

  useEffect(() => {
    if (!show) {
      setProgress(0)
      return;
    }

    const interval = setInterval(() => {
      if (progress === 100) {
        clearInterval(interval)
      } else {
        setProgress(progress + 1)
      }
    }, 100)

    return function cleanup() {
      clearInterval(interval)
    };

  }, [show, progress])

  if (!show) return null

  return (
    <div className="absolute flex items-center justify-center bg-red-30 w-20 h-20 animate-pulse z-40">
      <svg className="h-full w-full fill-slate-700" fillOpacity={0.7} viewBox="0 0 100 100" >
        <circle className="relative h-full w-full stroke-current fill-slate-300" cx="50" cy="50" r="30" />
        <circle
          className="relative h-full w-full stroke-current text-white"
          cx="50" cy="50" r="30"
          strokeDasharray={dasharray}
          strokeDashoffset={dashoffset}
          strokeWidth={5}
        />
      </svg>
      <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center text-center">
        <span className="text-xs">{`${progress}%`}</span>
      </div>
    </div>
  );

}

export default ProgressCircle