import React, { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import NoSearchResults from './NoSearchResults';
import Loading from './Loading';
import Error from './Error'
import EmptyLikes from './EmptyLikes';

export enum ContentType {
    EMPTY_SEARCH_RESULTS = "EMPTY_SEARCH_RESULTS",
    EMPTY_LIKES = "EMPTY_LIKES",
    LOADING = "LOADING",
    ERROR = "ERROR",
    DEFAULT = "DEFAULT"

}

type ErrorOptions = {
    tryAgain?: () => void
    loading?: boolean
}

type Props = {
    contentType?: ContentType
    options?: ErrorOptions
}
const UIIndicator: React.FC<Props> = ({ contentType, options }) => {
    const appContext = useContext(AppContext)
    return (
        <div className="flex my-5 bg-slate-100 rounded p-5 items-center justify-center" style={{ width: appContext.postSize.width, height: appContext.postSize.height }}>
            <div className='mb-16'>
                <UIIndicatorContent contentType={contentType} options={options} />
            </div>
        </div>
    )
}

type UIIndicatorContentProps = {
    contentType?: ContentType
    options?: ErrorOptions
}

const UIIndicatorContent: React.FC<UIIndicatorContentProps> = ({ contentType, options }) => {
    switch (contentType) {
        case ContentType.EMPTY_SEARCH_RESULTS:
            return <NoSearchResults />
        case ContentType.LOADING:
            return <Loading />
        case ContentType.EMPTY_LIKES:
            return <EmptyLikes />
        case ContentType.ERROR:
            return <Error tryAgain={options?.tryAgain} loading={options?.loading} />
        default:
            return null
    }

}



export default UIIndicator