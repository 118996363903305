import { GenerateImageParams, Post } from '../types';
import { getAppCheckToken } from './appCheck';

export const generateImage = async (generateImageParam: GenerateImageParams): Promise<Post[] | null> => {
    const token = await getAppCheckToken();

    const url = process.env.REACT_APP_PROD_GENERATE_TEMP_IMAGE_URL
    if (!token || !url) return null;

    const apiResponse = await fetch(url, {
        method: "POST",
        headers: {
            'X-Firebase-AppCheck': token,
        },
        body: JSON.stringify(generateImageParam)
    });

    const response = await apiResponse.json();

    if (response.statusCode > 200) {
        throw new Error('Error while generating image')
    }

    return response.data as unknown as Post[]
}