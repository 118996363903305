import React, { useEffect, useRef, useState } from 'react'

type SlideProps = {
    action: () => void
    actionTitle: string
    backAction?: () => void
    backActionTitle?: string
    image?: string
    video?: string
    title: string
    description: string
}

const Slide: React.FC<SlideProps> = ({ action, actionTitle, backAction, backActionTitle, image, video, title, description }) => {
    const [fade, setFade] = useState<boolean>(false)
    const [videoProgress, setVideoProgress] = useState<number>(0)
    const videoRef = useRef<HTMLVideoElement>(null)


    useEffect(() => {
        setFade(true)
    }, [])

    useEffect(() => {
        if (!videoRef || !videoRef.current) return;

        const interval = setInterval(() => {
            if (!videoRef || !videoRef.current) return;

            if (videoProgress === 100) {
                setVideoProgress(0)
            }

            const progress = Math.round((videoRef.current?.currentTime / videoRef.current?.duration) * 100)
            setVideoProgress(progress)
        })


        return () => {
            clearInterval(interval)
        }
    }, [videoProgress])

    return (
        <div className={`flex flex-col ${fade ? 'transition-opacity ease-in duration-500 opacity-100' : 'opacity-0'}`}>
            {image && <img className='rounded-md' src={image} alt={"Welcome"} />}

            {video && <>
                <video ref={videoRef} controls={false} loop autoPlay playsInline> <source src={video} type="video/mp4" />Your browser does not support the video tag.</video>
                <div className="w-full bg-gray-200 rounded-full mt-2 h-1.5 dark:bg-gray-700">
                    <div className="bg-gradient-to-r from-cyan-500 to-blue-500 h-1.5 rounded-full" style={{ width: `${videoProgress}%` }}></div>
                </div>
            </>}

            <div className='mb-10 mt-3'>
                <h2 className='font-bold pb-2 text-center'>{title}</h2>
                <p className='font-thin text-sm text-center whitespace-pre-wrap' > {description}</p>
            </div>

            <div className={`flex ${backAction ? 'justify-between' : 'justify-end'}`}>
                {backAction && backActionTitle && <button className="justify-self-start ease-out duration-300 hover:scale-110 hover:text-white w-auto whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-lg px-7 py-2" onClick={() => backAction()}>{backActionTitle}</button>}

                <button className="ease-out duration-300 hover:scale-110 hover:text-white w-auto whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-lg px-7 py-2" onClick={() => action()}>{actionTitle}</button>
            </div>


        </div>
    )
}


export default Slide