import React, { useEffect } from "react"
import { HeartIcon, ArrowDownOnSquareIcon, ShareIcon } from "@heroicons/react/24/solid"
import { Post } from "../../types"
import LikeAnimation from "../LikeAnimation"
import useLikes from "../../hooks/useLikes"

type Props = {
    show: boolean
    post: Post | null
    handleShareAction: () => void,
    handleDownloadAction: () => void,
}


const Actions: React.FC<Props> = ({ show, post, handleShareAction, handleDownloadAction }) => {
    const likesCount = post?.likes ? post.likes.likesCount : 0
    const { liked, setLiked, showAnimation, handleLikeClick, setShowAnimation } = useLikes(post?.id || '', likesCount)

    useEffect(() => {
        setLiked(false)
    }, [post, setLiked])

    const handleDownloadClick = (event: any) => {
        if (!post) {
            event.preventDefault();
        } else {
            handleDownloadAction();
        }
    }

    const handleShare = async () => {
        if (!post) return;

        await navigator.clipboard.writeText(`https://shospot.com/post/${post.id}`)
        handleShareAction()
    }
    const imageDownloadUrl = process.env.REACT_APP_FIREBASE_FUNCTION_DOWNLOAD_IMAGE
    return (
        <div className={`flex flex-col dark:border-gray-700 self-start items-end ${show ? ' scale-y-1 opacity-100' : 'scale-y-0 opacity-20'} duration-300 ease-in-out transition-transform transform`}>
            <ul className="flex flex-col flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 items-start">
                <li>
                    <button onClick={handleLikeClick} disabled={!post} className={`inline-flex hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 px-2 mb-4 rounded-t-lg group cursor-pointer`}>
                        <>
                            <LikeAnimation show={showAnimation} onComplete={() => setShowAnimation(false)} />
                            {!showAnimation && <HeartIcon className={`group-hover:text-white w-4 h-4 ${liked ? 'text-white' : 'text-gray-400'}`} />}
                        </>
                    </button>
                </li>
                <li>
                    <button onClick={handleShare} disabled={!post} className={`inline-flex hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 px-2 mb-4 rounded-t-lg group cursor-pointer`}>
                        <ShareIcon className={`text-gray-400 group-hover:text-white w-5 h-5`} />
                    </button>
                </li>
                <li>
                    <a href={`${imageDownloadUrl}?image=${encodeURI(post?.image || '')}`} download='shospot.png' onClick={handleDownloadClick} className={`inline-flex hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 px-2 mb-4 rounded-t-lg group`}>
                        <ArrowDownOnSquareIcon className={`text-gray-400 group-hover:text-white w-5 h-5`} />
                    </a>

                </li>
            </ul>
        </div>
    )
}


export default Actions