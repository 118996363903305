import React, { useCallback, useEffect, useState } from 'react';
import './App.css';

import Post from './components/Post'
import { Post as PostType } from './types'

import Search from './components/Search';
import UIIndicator, { ContentType } from './components/UIIndicator';
import LoadingButton from './components/LoadingButton';
import * as searchService from './services/search'
import Modal, { ModalSize } from './components/Modal';

import welcomeImageLarge from './assets/welcome_large.png'
import welcomeImageMedium from './assets/welcome_medium.png'
import { WELCOME_MODAL } from './constants/localStorage';
import { getStorageValue, setStorageValue } from './utils/localStorage';

function HomeScreen() {
    const [posts, setPosts] = useState<PostType[]>([]);
    const [pages, setPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [loading, setLoading] = useState(false);
    const [loadMore, setLoadMore] = useState(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false)

    const hasUserSeenWelcomeModal = getStorageValue(WELCOME_MODAL, false)
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(!hasUserSeenWelcomeModal)

    const getPosts = async () => {
        setLoading(true)
        const result = await searchService.getPosts()
        setPosts(result.posts)
        setPages(result.numberOfPages)
        setLoading(false)
    }

    const getMorePost = async () => {

        if (currentPage === pages) return;

        setLoadMore(true)
        const result = await searchService.getPosts(currentPage + 1)
        const newPosts = [...posts, ...result.posts]
        setPosts(newPosts)
        setCurrentPage(currentPage + 1)
        setLoadMore(false)
    }

    const getPostsCallBack = useCallback(() => {
        getPosts().catch(() => {
            setError(true)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (posts.length > 0) return;

        getPosts().catch(() => {
            setError(true)
            setLoading(false)
        });
    }, [posts.length])

    const showLoadMoreButton = (currentPage < pages) && (posts.length > 0) && !searching

    return (
        <div className="flex flex-col items-center justify-center">

            <Search onSearch={setSearching} />

            <div className='container flex items-center flex-col h-max'>
                <div className='flex flex-col items-center mb-10'>
                    {
                        !searching && posts.map((post, index) => <Post key={`${post.id}-${index}`} post={post} />)
                    }

                    {showLoadMoreButton && <LoadingButton loading={loadMore} onClick={() => getMorePost()} title="Load more" />}
                </div>

                {loading && !error && <UIIndicator contentType={ContentType.LOADING} />}

                {error && <UIIndicator contentType={ContentType.ERROR} options={{ tryAgain: () => getPostsCallBack(), loading }} />}
            </div>

            <Modal showModal={showWelcomeModal} title={'Shospot'} subTitle={'Welcome'} modalSize={ModalSize.Large} >
                <>
                    <img className='lg:block md:block hidden' src={welcomeImageLarge} alt={'Welcome to shospot'} />
                    <img className='lg:hidden md:hidden block' src={welcomeImageMedium} alt={'Welcome to shospot'} />

                    <button className="self-end mt-4 w-fit ease-out duration-300 hover:scale-110 hover:text-white whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-lg px-7 py-2" onClick={() => {
                        setShowWelcomeModal(false)
                        setStorageValue(WELCOME_MODAL, true)
                    }}>{"Lets Go!"}</button>
                </>
            </Modal>
        </div>
    );
}

export default HomeScreen;

