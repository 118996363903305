import { getAppCheckToken } from "./appCheck";

export const consentForEmailUpdate = async (emailAddress: string) => {
    // Include the App Check token with requests to your server.
    const token = await getAppCheckToken();
    const url = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_GET_ADD_EMAIL_URL : process.env.REACT_APP_PROD_GET_ADD_EMAIL_URL
    if (!token || !url) throw new Error('Somethnig went wrong');

    await fetch(url, {
        method: "POST",
        headers: {
            'X-Firebase-AppCheck': token,
        },
        body: JSON.stringify({ email: emailAddress })
    });
}