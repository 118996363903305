import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { rgbStringToHex } from '../../utils/createColourSpectrum';
import { generateMonochromaticPalette } from '../../utils/generateMonochromaticPalette';
import { AnalyseToolType, ColourPalette, GenerateImageParams, HeaderType, InformationModalData, ObjectsInformation, Post, RailItem } from '../../types';
import ToolMenu from './StudioMenu';
import { generateImage } from '../../services/studioApi';
import ToolLoading from './StudioLoading';

import Tags from '../Tags';
import Modal, { ModalSize } from '../Modal';
import AIRobot from '../AIRobot';

import WelcomeModal from './WelcomeModal';
import Slide from './Slide'

import backgroundImage from '../../assets/pattern.svg'
import SidePanelMobile from './SidePanelMobile';
import SidePanelContent from './SidePanelContent';
import { AppContext, SCREEN_SIZE } from '../../context/AppContext';

import welecomeHelp from '../../assets/help_welcome.png'
import moodBoard from '../../assets/mood_board.png'
import contemporary from '../../assets/contemporary.jpeg'
import industrial from '../../assets/industrial.jpeg'
import minimalist from '../../assets/minimalist.jpg'
import midcenturyModern from '../../assets/midcentury_modern.jpeg'
import modern from '../../assets/modern.jpeg'
import Slider from './Slider';
import LeftSideMenu from '../LeftSideMenu';
import Actions from './Actions';
import ImageControls from './ImageControls';
import ProgressCircle from '../ProgressCircle';
import TutorialModal from './TutorialModal';

import { getStorageValue, setStorageValue } from '../../utils/localStorage';
import { STUDIO_WELCOME_MODAL } from '../../constants/localStorage'

import Toast from '../Toast';
import { useToast } from '../../hooks/useToast';

type Props = {
  // any props you need here
  post?: Post
}

const Studio: React.FC<Props> = ({ post }) => {
  const appContext = useContext(AppContext)

  const [selectedPostIndex, setSelectedPostIndex] = useState<number>(0)

  const defaultPost = post ? [post] : null
  const [posts, setPosts] = useState<Post[] | null>(defaultPost)

  const [loading, setLoading] = useState<boolean>(false)

  const [error, setError] = useState<boolean>(false)
  const hasUserSeenWelcomeModal = getStorageValue(STUDIO_WELCOME_MODAL, false)
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(!hasUserSeenWelcomeModal)
  const [informationModal, setInformationModal] = useState<InformationModalData | null>(null)
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false)

  const [tutorialActionModal, setTutorialActionModal] = useState<boolean>(false)
  const [boardActionModal, setBoardActionModal] = useState<boolean>(false)


  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [mouseCoord, setMouseCoord] = useState<{ x: number, y: number } | null>(null);

  const [enableColourInspector, setEnableColourInspector] = useState<boolean>(false)

  // Left Panel state
  const [headerSelected, setHeaderSelected] = useState<HeaderType>(defaultPost ? HeaderType.ANALYSE : HeaderType.CREATE)

  const spaceRail: RailItem[] = [
    { title: "Living Room", image: backgroundImage },
    { title: "Kitchen", image: backgroundImage },
    { title: "Bedroom", image: backgroundImage },
    { title: "Dining Room", image: backgroundImage },
    { title: "Foyer", image: backgroundImage },
    { title: "Attic", image: backgroundImage }
  ]
  const [spcaeSelected, setSelectedSpace] = useState<RailItem | null>(null)

  const styleRail: RailItem[] = [
    { title: "Modern", image: modern },
    { title: "Mid-Century Modern", image: midcenturyModern },
    { title: "Minimalist", image: minimalist },
    { title: "Contemporary", image: contemporary },
    { title: "Industrial", image: industrial }
  ]
  const [styleSelected, setSelectedStyle] = useState<RailItem | null>(null)

  const [colourPaletteSelected, setColourPalette] = useState<ColourPalette | null>(null)

  const [colourSpectrum, setColourSpectrum] = useState<string[] | undefined>()
  const [dominantColours, setDominantColours] = useState<string[] | undefined>()

  const [analyseToolSelected, setAnalyseToolSelected] = useState<AnalyseToolType>(AnalyseToolType.COLOUR)

  const [selectedObject, setSelectedObject] = useState<ObjectsInformation>()

  const [numberOfImage, setNumberOfImage] = useState<any>(1)

  const { toggleToast, showToast, toastMessage } = useToast()

  const extractColourFromGivenCoord = useCallback((x: any, y: any) => {

    // Get the dimensions of the image
    const imageRect = canvasRef.current?.getBoundingClientRect();

    if (!imageRect) return;

    // Calculate the position of the mouse cursor relative to the image
    const xPos = x - imageRect.left;
    const yPos = y - imageRect.top;

    setMouseCoord({ x: xPos, y: yPos })

    if (!canvasRef.current) return


    // Get the pixel data of the image at the current position of the mouse cursor
    const imageData = canvasRef.current.getContext('2d')?.getImageData(xPos, yPos, 1, 1);

    if (!imageData) return;

    // Extract the color data from the pixel data
    const [r, g, b, a] = imageData.data;

    return `rgb(${r}, ${g}, ${b})`
  }, [])

  const handleMouseMove = useCallback((event: { clientX: any; clientY: any; }) => {

    if (!enableColourInspector) return;
    const selectedRGBColour = extractColourFromGivenCoord(event.clientX, event.clientY)

    if (!selectedRGBColour) return;
    // Set the selected color state to the RGB values of the pixel
    setSelectedColor(selectedRGBColour);
  }, [enableColourInspector, extractColourFromGivenCoord]);

  const handleMouseClick = useCallback(() => {

    // Call the mouse Over function to re-use its functionality for an in click
    // When on an tablet/ mobile phone
    if (!selectedColor) return;

    const hex = rgbStringToHex(selectedColor)
    const colourPalette = generateMonochromaticPalette(hex);
    setColourSpectrum(colourPalette)

  }, [selectedColor])

  const handleMouseClickMobile = useCallback((event: { touches: any }) => {

    if (!enableColourInspector) return;

    const touch = event.touches[0]
    const selectedColor = extractColourFromGivenCoord(touch.clientX, touch.clientY)

    if (!selectedColor) return
    setSelectedColor(selectedColor)
    const hex = rgbStringToHex(selectedColor)
    const colourPalette = generateMonochromaticPalette(hex);

    setColourSpectrum(colourPalette)

  }, [enableColourInspector, extractColourFromGivenCoord])

  const resetOptions = () => {
    setColourSpectrum(undefined)
    setEnableColourInspector(false)
    setDominantColours(undefined)
  }

  const handleGenerateImage = async (random?: boolean) => {

    if (!styleSelected || !spcaeSelected || loading) return;

    resetOptions()
    setLoading(true)

    const params: GenerateImageParams = {
      space: spcaeSelected.title,
      style: styleSelected.title,
      colourPalette: colourPaletteSelected?.name || null,
      numberOfImage,
      random: !!random
    }
    const generatedImages = await generateImage(params).catch((error) => {
      setError(true)
      setLoading(false)
    })

    if (generatedImages) {
      setPosts(generatedImages)
      setLoading(false)
    }
  }

  const drawImage = useCallback(() => {
    const base_image = new Image();
    base_image.crossOrigin = "Anonymous"
    base_image.style.objectFit = 'contain'
    base_image.width = 512
    base_image.height = 512


    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (!context) return;

      base_image.src = (posts && posts[selectedPostIndex]) ? posts[selectedPostIndex].image : welecomeHelp;
      base_image.onload = () => {
        context.imageSmoothingEnabled = false;
        context.drawImage(base_image, 0, 0, 512, 512);
      }
    }

  }, [posts, selectedPostIndex])


  //Reset colour spectrum states on toggle
  useEffect(() => {
    setColourSpectrum(undefined)
    setMouseCoord(null)
    setSelectedColor(null)

  }, [enableColourInspector])

  useEffect(() => {
    const setCanvasWidth = () => {
      if (canvasRef.current) {
        if (window.outerWidth < 512) {
          canvasRef.current.width = window.innerWidth - 20
          drawImage();
        } else {
          canvasRef.current.width = 512
          drawImage();
        }
      }
    }

    //This is currently causing multiple renders 
    // window.addEventListener("resize", () => {
    //   setCanvasWidth()
    // });

    setCanvasWidth()

  }, [drawImage])

  //Set up Canvas
  useEffect(() => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext('2d');

      if (!context) return;


      if (appContext.screenSize === SCREEN_SIZE.LARGE) {
        canvasRef.current.onmousemove = handleMouseMove
        canvasRef.current.onclick = handleMouseClick
      } else {
        canvasRef.current.ontouchstart = handleMouseClickMobile
      }

      drawImage()
    }
  }, [appContext.screenSize, drawImage, enableColourInspector, handleMouseClick, handleMouseClickMobile, handleMouseMove, posts])

  //Set Dominant colour 
  useEffect(() => {
    if (!posts) return;
    const firstFive = posts[selectedPostIndex].metaData.colors.slice(0, 5)
    const dominantColours = firstFive.map((color) => color?.hex || '')
    setDominantColours(dominantColours)

  }, [posts, selectedPostIndex])


  const getMouseCoords = () => {
    let x = mouseCoord?.x || 0;
    let y = mouseCoord?.y || 0;

    if (appContext.screenSize === SCREEN_SIZE.LARGE) {
      x = x + 10
      y = y + 0
    }

    return { x, y }
  }

  const buttonDisabled = (!styleSelected?.title || !spcaeSelected?.title) || loading

  return (
    <div className='relative flex-row h-screen w-full lg:grid lg:grid-cols-8'>

      <div className='col-span-6 relative h-full flex items-center justify-center p-4 sm:mt-10'>
        <LeftSideMenu tutorialAction={() => setTutorialActionModal(!tutorialActionModal)} boardAction={() => setBoardActionModal(!boardActionModal)} />
        <div className='relative flex flex-row space-x-2'>

          <div className='relative flex flex-row lg:space-x-4'>
            <ToolMenu show={headerSelected === HeaderType.ANALYSE} selected={analyseToolSelected} onSelect={setAnalyseToolSelected} />
            <div className='flex flex-col space-y-4'>
              <div className='flex flex-row relative z-0'>
                <div className='relative bg-slate-500 bg-opacity-20 rounded-md'>
                  <Toast show={showToast} message={toastMessage} />
                  <ProgressCircle show={loading} />

                  {posts && posts.length > 0 ? <canvas className='flex rounded-md' ref={canvasRef} id="canvas" style={{ width: '100%', height: '100%' }} width={512} height={512} /> :
                    <img className=' z-40 object-contain rounded-md' src={welecomeHelp} alt={'Instructions on how to use shospot'} style={{ maxWidth: 512, maxHeight: 512, width: '100%', height: '100%' }} />
                  }

                  {/* remove tags for now */}
                  {/* {analyseToolSelected === AnalyseToolType.PRODUCTS && <Tags objects={posts ? posts[selectedPostIndex]?.metaData.objects : []} onClick={setSelectedObject} />} */}
                  <ToolLoading show={loading} />
                </div>

                {enableColourInspector &&
                  <span className='visible w-10 h-10 absolute rounded-full shadow-2xl border border-solid border-neutral-900'
                    style={{ backgroundColor: selectedColor || '', transform: `translate(${getMouseCoords().x}px, ${getMouseCoords().y}px)` }}></span>
                }

              </div>

              <div className="flex flex-row justify-between">

                <div className='flex flex-row items-center space-x-1'>
                  <span className='font-small text-sm font-medium text-white-500 opacity-50'>{"Images:"}</span>
                  <button onClick={() => setInformationModal({ title: "Images", description: "This will determine the number of images to render at a time, Just remember, the more images you render, the longer it'll take for our robots to work their magic. But hey, good things come to those who wait, right?" })} className=' hover:animate-pulse duration-300 ease-in-out transition-transform transform hover:-translate-y-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                  </button>
                  <Slider values={[1, 2, 3, 4]} disabled={loading} currentValue={numberOfImage} onChange={(value) => setNumberOfImage(value)} />

                </div>


                <button disabled={buttonDisabled} className={`${buttonDisabled ? 'opacity-50 cursor-not-allowed' : "cursor-pointer"} ease-out duration-300 hover:scale-110 hover:text-white  w-auto whitespace-nowrap border border-white rounded-lg px-7 py-2`} onClick={() => {
                  setShowSidePanel(false)
                  handleGenerateImage()
                }}>Generate</button>
              </div>
            </div>

          </div>

          <div className='flex flex-col'>
            <Actions show={true} post={posts ? posts[selectedPostIndex] : null} handleShareAction={() => toggleToast("Copied link")} handleDownloadAction={() => toggleToast("Download in progress")} />

            <ImageControls posts={posts} onSelectPostIndex={(index) => setSelectedPostIndex(index)} selectedPostIndex={selectedPostIndex} disabled={loading} />

          </div>
        </div>
      </div>

      <div className='col-span-2 sidePanel lg:visible invisible flex flex-col h-screen rounded shadow-lg bg-slate-600 backdrop-blur-lg backdrop-opacity-30 p-4 overflow-x-hidden'>
        <SidePanelContent
          headerSelected={headerSelected}
          setHeaderSelected={setHeaderSelected}
          setInformationModal={setInformationModal}
          spaceRail={spaceRail}
          spcaeSelected={spcaeSelected}
          setSelectedSpace={setSelectedSpace}
          styleRail={styleRail}
          styleSelected={styleSelected}
          setSelectedStyle={setSelectedStyle}
          setColourPalette={setColourPalette}
          colourPaletteSelected={colourPaletteSelected}
          analyseToolSelected={analyseToolSelected}
          dominantColours={dominantColours}
          setEnableColourInspector={setEnableColourInspector}
          enableColourInspector={enableColourInspector}
          colourSpectrum={colourSpectrum}
          selectedObject={selectedObject}
        />

      </div>

      <div className='flex flex-col absolute lg:invisible top-6 right-5 items-center space-y-1'>
        <button onClick={() => setShowSidePanel(true)} className='text-white bg-slate-500 p-2 rounded-full w-fit h-fit'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
          </svg>
        </button>
        <span className='text-xs text-slate-300'>start here</span>
      </div>


      <SidePanelMobile show={showSidePanel} onClose={() => setShowSidePanel(false)}>
        <SidePanelContent
          headerSelected={headerSelected}
          setHeaderSelected={setHeaderSelected}
          setInformationModal={setInformationModal}
          spaceRail={spaceRail}
          spcaeSelected={spcaeSelected}
          setSelectedSpace={setSelectedSpace}
          styleRail={styleRail}
          styleSelected={styleSelected}
          setSelectedStyle={setSelectedStyle}
          setColourPalette={setColourPalette}
          colourPaletteSelected={colourPaletteSelected}
          analyseToolSelected={analyseToolSelected}
          dominantColours={dominantColours}
          setEnableColourInspector={setEnableColourInspector}
          enableColourInspector={enableColourInspector}
          colourSpectrum={colourSpectrum}
          selectedObject={selectedObject}
        />
      </SidePanelMobile>

      {/* Information modal */}
      <Modal title='Information' subTitle='' showModal={!!informationModal}>
        <Slide action={() => setInformationModal(null)} actionTitle={'Ok'} title={informationModal?.title || ''} description={informationModal?.description || ''} />
      </Modal>


      {/* Welcome modal */}
      <Modal title='Shospot' subTitle='Welcome!' showModal={showWelcomeModal}>
        <WelcomeModal onClose={() => {
          setShowWelcomeModal(false)
          setStorageValue(STUDIO_WELCOME_MODAL, true)
        }} />
      </Modal>

      {/* Tutorial action modal */}
      <Modal title='Shospot' subTitle='Tutorials' showModal={tutorialActionModal}>
        <TutorialModal onClose={() => setTutorialActionModal(false)} />
      </Modal>

      {/* Tutorial action modal */}
      <Modal title='Shospot' subTitle='Bringing inspiration and organisation to your design projects' showModal={boardActionModal} onClose={() => setBoardActionModal(false)} modalSize={ModalSize.Large}>

        <img src={moodBoard} alt={"Moodboard coming soon"} />
        <button className="self-end mt-4 w-fit ease-out duration-300 hover:scale-110 hover:text-white whitespace-nowrap bg-gradient-to-r from-green-400 to-blue-500 rounded-lg px-7 py-2" onClick={() => {
          setBoardActionModal(false)
          appContext.setShowAuthModal(true)
        }}>{"Join the waiting list"}</button>
      </Modal>

      {/* Error modal  */}
      <Modal title='Error' subTitle='OOPS!' showModal={error} enableBackgroundDismissal onClose={() => setError(!error)} >
        <div className='flex flex-row space-x-4 items-center justify-center mt-5'>
          <AIRobot />
          <p className='font-medium'> It looks like our AI is having a bit of a glitch. Hang tight while we fix things up and get you back to creating amazing images. </p>
        </div>
      </Modal>


    </div>
  );
};

export default Studio;
