import React from 'react'
import { PhotoIcon, Squares2X2Icon, SquaresPlusIcon, AcademicCapIcon } from "@heroicons/react/24/solid"


type Props = {
    tutorialAction: () => void
    boardAction: () => void
}


const LeftSideMenu: React.FC<Props> = ({ tutorialAction, boardAction }) => {
    return (
        <aside className="flex w-fit absolute top-0 left-0">
            <div className="px-2 py-4 overflow-y-auto rounded shadow-sm lg:bg-gray-900 bg-opacity-20">
                <ul className="flex lg:flex-col md:flex-col flex-row lg:space-y-2 lg:space-x-0 space-x-2">
                    {/* <li>
                        <a href="#" className="flex w-full flex-col justify-center items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                            <Squares2X2Icon className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 whitespace-nowrap text-xm" style={{ fontSize: "smaller" }}>generate</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="flex w-full flex-col justify-center items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                            <PhotoIcon className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                            <span className="flex-1 whitespace-nowrap text-sm" style={{ fontSize: "smaller" }}>reimagine</span>
                        </a>
                    </li> */}

                    <li>
                        <button onClick={boardAction} className="flex w-full flex-col justify-center items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 space-y-1">
                            <div className=' bg-slate-500 p-2 rounded-full w-fit h-fit text-white'>
                                <SquaresPlusIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
                            </div>
                            <span className='text-xs text-slate-300'> boards</span>
                        </button >
                    </li>
                    <li>
                        <button onClick={tutorialAction} className="flex w-full flex-col justify-center items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 space-y-1">
                            <div className=' bg-slate-500 p-2 rounded-full w-fit h-fit text-white'>
                                <AcademicCapIcon className="flex-shrink-0 w-5 h-5 transition duration-75" />
                            </div>
                            <span className='text-xs text-slate-300'> tutorials</span>
                        </button>
                    </li>
                </ul>
            </div>
        </aside >
    )
}



export default LeftSideMenu