import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Post from './components/Post';
import UIIndicator, { ContentType } from './components/UIIndicator';
import * as searchService from './services/search'
import { Post as PostType } from './types';
import { getStorageValue } from './utils/localStorage';

const LikesScreen: React.FC = () => {
    const [likes] = useState(() => getStorageValue('likes', {}))
    const [posts, setPosts] = useState<PostType[]>();
    const [error, setError] = useState<boolean>(false);

    const navigate = useNavigate();

    const loading = !posts && !error

    useEffect(() => {
        if (posts) return;

        const getPost = async () => {
            try {
                const posts = await searchService.getPostsByIds(Object.keys(likes))
                setPosts(posts.reverse());
                setError(false)
            } catch (error) {
                setError(true)
            }
        }

        getPost()
    }, [likes, posts])

    return (
        <div className="flex relative pt-4 w-auto h-full md:h-auto justify-center items-center">
            <div className="relative rounded-lg w-fit h-full justify-center self-center shadow ">
                <div className="flex justify-between items-center p-4 rounded-t">
                    <h1 className="ml-2 font-medium text-center text-slate-400">Likes</h1>
                </div>
                {/* <!-- Modal body --> */}
                <div className="p-6 space-y-6">
                    {posts && !error && posts.map((post, index) => <Post key={`${post.id}-${index}`} post={post} />)}

                    {loading && <UIIndicator contentType={ContentType.LOADING} />}

                    {Object.keys(likes).length === 0 && <UIIndicator contentType={ContentType.EMPTY_LIKES} />}
                </div>
            </div>
        </div>
    )
}

export default LikesScreen