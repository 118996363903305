import React, { useEffect, useRef } from 'react'
import Lottie from 'lottie-react'
import LikesWhite from '../lottie/like_white.json'


type Props = {
  show: boolean,
  onComplete: () => void
}
const LikeAnimation: React.FC<Props> = ({ show, onComplete }) => {
  type LottieRefType = HTMLDivElement & { play: () => void, stop: () => void }
  const lottieRef = useRef<LottieRefType>(null)

  useEffect(() => {
    if (!show) return;
    lottieRef?.current?.play()

  }, [show])


  if (!show) return null;

  return <Lottie ref={lottieRef} onLoopComplete={() => {
    lottieRef?.current?.stop();
    onComplete()
  }} autoPlay={false} className='opacity-90' style={{ width: 16, height: 16, transform: "scale(2.3)" }} animationData={LikesWhite} />
}

export default LikeAnimation