import algoliasearch from 'algoliasearch';
import { Post, Product } from '../types';
import { enrichPostsWithLikes, enrichPostWithLikes } from './post';

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || "", process.env.REACT_APP_ALGOLIA_KEY || "");
const postIndex = client.initIndex('posts');
const productIndex = client.initIndex('products');

export const getPosts = async (page = 0): Promise<{ posts: Post[], numberOfPages: number }> => {
    // Get all records as an iterator
    const hits: any = await postIndex.search('', {
        hitsPerPage: 20,
        page
    });

    const posts = hits.hits as Post[]
    const enrichedPosts = await enrichPostsWithLikes(posts)

    return { posts: enrichedPosts, numberOfPages: (hits.nbPages - 1) }
}

export const getPost = async (postId: string): Promise<Post> => {
    const object = await postIndex.getObject(postId)
    const post = object as unknown as Post

    const enrichedPost = await enrichPostWithLikes(post)
    return enrichedPost
}

export const getPostsByIds = async (postIds: string[]): Promise<Post[]> => {
    const objects = await postIndex.getObjects(postIds)
    const post = objects.results as unknown as Post[]

    const enrichedPost = await enrichPostsWithLikes(post)
    return enrichedPost
}


export const getProductsByIds = async (poroductIds: string[]): Promise<Product[]> => {
    const objects = await productIndex.getObjects(poroductIds)
    const products = objects.results as unknown as Product[]
    return products
}

