import { doc, getDoc, getFirestore, runTransaction, setDoc } from 'firebase/firestore';
import { app } from '../firebase/initFirebaseForData';
import { LikeAction, Likes } from '../types';


//Todo refactor to same function 
export const updatePostLikes = async (postID: string, likeAction: LikeAction) => {
    try {
        const docRef = doc(getFirestore(app), 'likes', postID)
        await runTransaction(getFirestore(app), async (transaction) => {

            const sfDoc = await transaction.get(docRef);
            let updatedLikesCount;
            if (!sfDoc.exists()) {
                addLikes(postID)
            } else {

                if (likeAction === LikeAction.LIKE) {
                    if (!sfDoc.data().likesCount) {
                        updatedLikesCount = 1
                    } else {
                        updatedLikesCount = sfDoc.data().likesCount + 1
                    }
                }

                if (likeAction === LikeAction.UNLIKE) {
                    if (!sfDoc.data().likesCount) {
                        updatedLikesCount = 0
                    } else {
                        updatedLikesCount = sfDoc.data().likesCount - 1
                    }
                }

                transaction.update(docRef, { likesCount: updatedLikesCount });
            }
        });

    } catch (e) {
        // console.log("Transaction failed: ==>", e);
    }
}

export const addLikes = async (postID: string) => {
    const docRef = doc(getFirestore(app), 'likes', postID)
    await setDoc(docRef, { likesCount: 1 })
}

export const getLikes = async (postID: string): Promise<Likes> => {
    const docRef = doc(getFirestore(app), 'likes', postID)

    const result = await getDoc(docRef)
    return result.data() as Likes
}
