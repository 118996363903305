import React from "react";

type Props = {
    showModal: boolean
    onClose?: () => void
    enableBackgroundDismissal?: boolean
    title: string
    subTitle?: string
    children: React.ReactNode,
    modalSize?: ModalSize
}

export enum ModalSize {
    Medium = "max-w-lg",
    Large = "max-w-6xl"
}


const Modal: React.FC<Props> = ({ showModal, onClose, title, subTitle, children, enableBackgroundDismissal, modalSize = ModalSize.Medium }) => {

    const onModalClose = () => {
        if (!onClose) return;
        onClose()
    }

    return (
        <>
            {showModal ? (
                <>
                    <div tabIndex={-1} aria-hidden="true" onClick={() => enableBackgroundDismissal ? onModalClose() : null} className="flex visible overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full h-full md:inset-0 h-modal backdrop-blur-md items-center justify-center">
                        <div className={`flex relative pixel p-4 ${modalSize} h-full md:h-auto items-center justify-center`}>
                            {/*content*/}
                            <div className="border-0 bg-regal-blue rounded-3xl shadow-lg relative flex flex-col w-full outline-none focus:outline-none justify-center">
                                {/*header*/}
                                {onClose && <div className="flex justify-between items-start p-4 rounded-t mt-2">
                                    <button onClick={onModalClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 ease-out duration-300 hover:scale-110 rounded-full text-sm p-1.5 ml-auto inline-flex items-center">
                                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>}
                                {/*body*/}
                                <div className="py-4 px-6 lg:px-8">

                                    <>
                                        <div className="space-y-2">
                                            <div className="flex flex-col md:flex-row lg:flex-row items-start md:items-center lg:items-center space-x-0 md:space-x-2 lg:space-x-2 space-y-2 md:space-y-0 lg:space-y-0">
                                                <h3 className="bg-gradient-to-r from-green-400 to-blue-500 text-base font-medium p-2 rounded-lg text-white justify-center items-center flex flex-row">
                                                    {title}
                                                </h3>
                                                <p className="self-start md:self-center lg:self-center text-sm">{subTitle}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-auto h-auto mt-2">
                                            {children}
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}


export default Modal