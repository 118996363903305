import React from 'react';

type Props = {
    width?: number
}

const ProductPlaceholder: React.FC<Props> = ({ width = 200 }) => {
    return (
        <div className="bg-gray-300 opacity-60 shadow rounded-xl px-4 pt-2 pb-4 max-w-sm w-full h-full mx-1" style={{ width, minWidth: width, maxWidth: width }}>
            <div className="animate-pulse flex flex-col">
                <div className="animate-pulse bg-slate-400 self-center items-center justify-center rounded-md h-32 w-32 my-2"></div>

                <div className="flex-1 justify-center space-y-2 py-1">
                    <div className="h-2 bg-slate-200 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-200  rounded col-span-2"></div>
                            <div className="h-2 bg-slate-200  rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-200  rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductPlaceholder