import React, { useState, useRef, useContext } from 'react';
import { ShareIcon, ArrowDownOnSquareIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import { ObjectsInformation, Post as PostType } from '../types'
import Labels from "./Labels";
import Tags from "./Tags";
import Colors from "./Colors";
import ProductsPanel from "./ProductsPanel";
import { AppContext } from '../context/AppContext';
import PostPlaceholder from './PostPlaceholder';
import { useToast } from '../hooks/useToast';
import Toast from './Toast';
import Like from './Like';
import { formatTime } from '../utils/formatTime';

type Props = {
    post: PostType
}

type FooterProps = {
    post: PostType,
    onShareActionComplete: () => void,
    onDownloadActionComplete: () => void
}

const PostFooter: React.FC<FooterProps> = ({ post, onShareActionComplete, onDownloadActionComplete }) => {
    const imageDownloadUrl = process.env.REACT_APP_FIREBASE_FUNCTION_DOWNLOAD_IMAGE
    const handleShareClick = async () => {
        await navigator.clipboard.writeText(`https://shospot.com/post/${post.id}`)
        onShareActionComplete()
    }

    return (
        <div className="p-2">
            <div className="flex flex-col justify-between space-y-4">
                <div className="flex flex-row w-full items-center">
                    <div className='flex flex-row items-center justify-between w-full'>
                        <Like postId={post.id} likesCount={post.likes ? post.likes.likesCount : 0} />

                        <p className="text-gray-400 text-xs text-left">{formatTime(post?.dateCreated as number)}</p>
                    </div>
                </div>

                <div className='flex flex-row justify-between items-center w-full'>

                    <Colors colors={post.metaData.colors} />

                    <div className='flex flex-row py-2 justify-between space-x-2'>

                        <div className='flex flex-col justify-center items-center rounded-lg self-end w-fit h-fit'>
                            <a href={`/studio/${post.id}`} className='ease-out duration-100 hover:scale-110 animate bg-gray-200 p-2 lg:hover:bg-regal-blue md:hover:bg-regal-blue text-gray-600 lg:hover:text-white md:hover:text-white rounded-full opacity-80'>
                                <AdjustmentsHorizontalIcon className='w-4 h-4' />
                            </a>
                            <span className='text-xs text-slate-300'>Studio</span>
                        </div>

                        <div className='flex flex-col justify-center items-center rounded-lg self-end  w-fit h-fit'>
                            <button onClick={() => handleShareClick()} className='ease-out duration-100 hover:scale-110 rounded-full animate bg-gray-200 p-2 hover:bg-regal-blue text-gray-600 hover:text-white opacity-80'>
                                <ShareIcon className='w-4 h-4 rounded-full text-inherit' />
                            </button>
                            <span className='text-xs text-slate-300'>Share</span>
                        </div>

                        <div className='flex flex-col justify-center items-center rounded-lg self-end  w-fit h-fit'>
                            <a href={`${imageDownloadUrl}?image=${encodeURI(post.image)}`} onClick={onDownloadActionComplete} download='shospot.png' className='ease-out duration-100 hover:scale-110 rounded-full bg-gray-200 p-2 hover:bg-regal-blue text-gray-600 hover:text-white opacity-80'>
                                <ArrowDownOnSquareIcon className='w-4 h-4 rounded-full text-inherit' />
                            </a>
                            <span className='text-xs text-slate-300'>Download</span>
                        </div>

                    </div>

                </div>


                <Labels labels={post.metaData.labels} />
            </div>
        </div>
    )
}

const Post: React.FC<Props> = ({ post }) => {
    const appContext = useContext(AppContext)
    const postRef = useRef<HTMLDivElement>(null)
    const imageRef = useRef<HTMLImageElement>(null)
    const [showProductsPanel, setShowProductsPanel] = useState(false)
    const [products, setProducts] = useState<string[] | null>([])
    const [imageLoading, setImageLoading] = useState(true)

    const { toggleToast, showToast, toastMessage } = useToast()

    const handleTagClick = (object: ObjectsInformation | undefined) => {
        if (!object) return;

        if (postRef !== undefined && postRef.current) {
            postRef?.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
        }

        setProducts(object?.products)
        setShowProductsPanel(true)
    }

    const handleProductsPanelClose = () => {
        setProducts(null)
        setShowProductsPanel(false)
    }

    const createImageServingUrl = () => `https://ik.imagekit.io/shospot/tr:w-${appContext.postSize.width},h-${appContext.postSize.height}/posts/${post.id}/${post.id}.jpeg?alt=media`

    return (
        <div className='relative'>
            <div ref={postRef} className={`relative ${imageLoading ? 'hidden' : 'block'} overflow-hidden`}>
                <div className="flex rounded-lg my-5 flex-col bg-white" style={{ width: appContext.postSize.width }}>
                    <div className="rounded-lg relative" style={{ width: appContext.postSize.width, height: appContext.postSize.height }}>
                        <img ref={imageRef} className="rounded-lg" style={{ height: 'inherit' }} src={createImageServingUrl()} alt={post.title} onLoad={() => setImageLoading(false)} />

                        <Tags objects={post.metaData.objects} onClick={handleTagClick} />
                    </div>

                    <PostFooter post={post} onShareActionComplete={() => toggleToast("Copied link")} onDownloadActionComplete={() => toggleToast("Download in progress")} />
                </div>
                <Toast show={showToast} message={toastMessage} />
                <ProductsPanel products={products} show={showProductsPanel} onClose={handleProductsPanelClose} />

            </div>
            {imageLoading && <PostPlaceholder abosolute={false} />}
        </div>
    )

}

export default Post