import React, { useRef } from 'react'
import HorizontalScrollButtons from './HorizontalScrollButtons';

type Props = {
    colourSpectrum: string[] | undefined
}
const ColourSpectrum: React.FC<Props> = ({ colourSpectrum, }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const defaultSpecturm = ["#000000", "#000000", "#000000", "#000000", "#000000"];
    const spectrum = colourSpectrum || defaultSpecturm
    return (

        <div className='flex flex-col lg:overflow-x-hidden md:overflow-x-auto sm:overflow-x-auto'>

            <HorizontalScrollButtons scrollContainerRef={scrollContainerRef} />
            <div ref={scrollContainerRef} className='rail flex flex-row space-x-2 py-4 overflow-x-auto'>
                {
                    spectrum?.map((colour, index) => (
                        <div key={`${colour}-${index}`} className='flex flex-col items-center space-y-2'>
                            <span className='flex visible w-10 h-10 rounded-full shadow-2xl border border-solid border-white' style={{ backgroundColor: colour }} />
                            <p className='text-white text-xs'>{colour}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ColourSpectrum
