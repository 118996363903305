import React, { useCallback, useEffect, useState } from 'react'


type Props = {
    scrollContainerRef: any,
}

const HorizontalScrollButtons: React.FC<Props> = ({ scrollContainerRef }) => {

    const [showNavigateButtons, setShowNavigateButtons] = useState<boolean>(false)

    const hasOverScroll = useCallback(() => {
        if (scrollContainerRef === null || scrollContainerRef.current === null) return false;
        return scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth
    }, [scrollContainerRef])

    useEffect(() => {
        setShowNavigateButtons(hasOverScroll())
    }, [hasOverScroll, scrollContainerRef])



    const scrollLeft = () => {
        if (scrollContainerRef === null || scrollContainerRef.current === null) return;

        scrollContainerRef.current.scrollLeft -= 80;
    }

    const scrollRight = () => {
        if (scrollContainerRef === null || scrollContainerRef.current === null) return;

        scrollContainerRef.current.scrollLeft += 80;
    }

    if (!showNavigateButtons) return null

    return (
        <div className="lg:flex px-2 pb-2 lg:visible hidden">
            <button onClick={scrollLeft} className='text-slate-200 opacity-50 hover:text-white hover:opacity-90'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clipRule="evenodd" />
                </svg>

            </button>


            <button onClick={scrollRight} className='text-slate-200 opacity-50 hover:text-white hover:opacity-90'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 flex">
                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clipRule="evenodd" />
                </svg>
            </button>
        </div>
    )
}


export default HorizontalScrollButtons