/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { InstantSearch, useSearchBox, UseSearchBoxProps } from 'react-instantsearch-hooks-web';
import SearchHits from './SearchHits';
import { AppContext } from '../context/AppContext';

type Props = {
    onSearch: (searching: boolean) => void
}

const SearchBox: React.FC<Props> = ({ onSearch }) => {
    const appContext = useContext(AppContext)
    const [searchQuery, setSearchQuery] = useState<string>("")
    let timerId: string | number | NodeJS.Timeout | undefined = useRef(undefined).current;

    const queryHook: UseSearchBoxProps['queryHook'] = useCallback((query: any, search: (arg0: any) => void,) => {

        if (query === "") return

        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            search(query)
        }, 300);

    }, []);

    const searchBoxApi = useSearchBox({
        queryHook,
    });

    useEffect(() => {
        if (searchQuery === "") {
            searchBoxApi.clear();
            onSearch && onSearch(false)
        }
        else {
            searchBoxApi.refine(searchQuery)
            onSearch && onSearch(true)
        }
    }, [onSearch, searchQuery, searchBoxApi])


    return (

        <div className="relative rounded-2xl py-4" style={{ width: appContext.postSize.width }}>
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </div>
            <input value={searchQuery} onChange={(event) => setSearchQuery(event.target.value)} type="search" id="default-search" autoComplete='off' className="block rounded-3xl p-4 pl-10 w-full text-sm text-gray-900 bg-white border border-gray-300 focus:border-regal-blue" placeholder="Living room ..." />
        </div>
    )
}


const Search: React.FC<Props> = ({ onSearch }) => {

    const [searching, setSearching] = useState(false)
    const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID || "", process.env.REACT_APP_ALGOLIA_KEY || "");

    //This is a proxy to prevent the first initial call to algolia 
    const searchClient: SearchClient = {
        ...algoliaClient,
        // @ts-ignore
        search(requests) {
            if (requests.every(({ params }) => !params?.query)) {

                return Promise.resolve({
                    results: requests.map(() => ({
                        hits: [],
                        nbHits: 0,
                        nbPages: 0,
                        page: 0,
                        processingTimeMS: 0,
                        hitsPerPage: 0,
                        exhaustiveNbHits: false,
                        query: '',
                        params: '',
                    })),
                });
            }

            return algoliaClient.search(requests);
        },
    };

    return (
        <InstantSearch searchClient={searchClient} indexName={"posts"} stalledSearchDelay={300}>
            <SearchBox onSearch={(searching) => {
                setSearching(searching)
                onSearch(searching)
            }} />
            <SearchHits searching={searching} />
        </InstantSearch>
    )
}

export default Search