import React from 'react';
type Props = {
    labels: (string | null)[]
}

const Labels: React.FC<Props> = ({ labels }) => {

    return (
        <div>
            {
                labels?.map((label, index) => <span key={`${label}-${index}`} className="inline-block bg-gray-200 rounded-full px-2 py-1 text-xs font-thin text-gray-700 mr-2 mb-2" > {`#${label}`} </span>)
            }
        </div>

    )
}

export default Labels