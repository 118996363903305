import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
    postId?: string
    url?: string
    title?: string
    description?: string
}

const MetaTags: React.FC<Props> = ({ postId, url, title, description }) => {
    const imageUrl = `https://ik.imagekit.io/shospot/tr:w-512,h-512/posts/${postId}/${postId}.jpeg?alt=media`
    const defaultImageUrl = "https://ik.imagekit.io/shospot/tr:w-512,h-512/posts/wmb5UAWwgtNMLmmd9YZ8/wmb5UAWwgtNMLmmd9YZ8.jpeg?alt=media"
    const defaultTitle = "Interior Design Made Easy with AI: Free AI-Inspired Decorating Ideas for Your Home, Find Your Perfect Design Style Now!"

    return (
        <Helmet>
            <title>{title || defaultTitle}</title>

            <meta property="og:type" content="website" data-react-helmet="true" />
            <meta property="og:site_name" content="Shospot" data-react-helmet="true" />
            <meta property="og:image" content={postId ? imageUrl : defaultImageUrl} data-react-helmet="true" />
            <meta property="og:image:type" content="image/jpeg" data-react-helmet="true" />
            <meta property="og:url" content={url ? url : "https://shospot.com"} data-react-helmet="true" />
            <meta property="og:image:alt" content="Shospot Ai" data-react-helmet="true" />
            <meta property="og:title" content={title ? title : "Shospot - Be inspired by quirky, fun and imaginative AI-generated interior designs."} data-react-helmet="true" />
            <meta property="og:description" content={description ? description : "Be inspired by quirky, fun and imaginative AI-generated interior designs."} data-react-helmet="true" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:title" content={title ? title : "Shospot - Be inspired by quirky, fun and imaginative AI-generated interior designs."} data-react-helmet="true" />
            <meta property="twitter:image" content={postId ? imageUrl : defaultImageUrl} data-react-helmet="true" />
            <meta property="twitter:description" content={description ? description : "Be inspired by quirky, fun and imaginative AI-generated interior designs."} data-react-helmet="true" />
            <meta property="twitter:url" content={url ? url : "https://shospot.com"} data-react-helmet="true" />
        </Helmet>

    )
}

export default MetaTags

